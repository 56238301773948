import React from "react";
import SubStationWise from "./SubStationWise";

const SubStationWiseReportView = () => {
  return (
    <div>
      <SubStationWise />
    </div>
  );
};

export default SubStationWiseReportView;
