import React from "react";
import Report from "../../Pages/Report";
const DivisionWiseReport = () => {
  return (
    <div>
      <Report title="Division" />
    </div>
  );
};

export default DivisionWiseReport;
