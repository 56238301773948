import React from "react";
import Report from "../../Pages/Report";
const CircleWiseReport = () => {
  return (
    <div>
      <Report title="Circle" />
    </div>
  );
};

export default CircleWiseReport;
