import React from "react";
import SignUp from "./components/SignUp";

function SignUpView() {
  return (
    <div>
      <SignUp />
    </div>
  );
}

export default SignUpView;
