import React, { useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";

const AutoLogout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      const decodedToken = jwtDecode(token);
      //   console.log("decodedToken", decodedToken);
      const expirationTime = decodedToken.exp * 1000; // Convert to milliseconds
      //   console.log("decodedToken", decodedToken);
      const currentTime = new Date().getTime();
      currentTime = currentTime + 7200000;

      if (expirationTime < currentTime) {
        // Token already expired
        handleLogout();
      } else {
        // Set a timeout to log out when token expires
        const timeout = setTimeout(() => {
          handleLogout();
        }, expirationTime - currentTime);

        // Clean up timeout if component unmounts
        return () => clearTimeout(timeout);
      }
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token"); // Remove token from localStorage
    navigate("/"); // Redirect to login page
    alert("Session expired. You have been logged out.");
  };

  return null;
};

export default AutoLogout;
