import React from "react";
import Report from "../../Pages/Report";
const SubDivisionWiseReport = () => {
  return (
    <div>
      <Report title="Sub Division" />
    </div>
  );
};

export default SubDivisionWiseReport;
