import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Table, notification } from "antd";
import {
  getApprovalDetails,
  approveUser,
  rejectUser,
} from "../../../../services/otherServices";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
const UserApproval = () => {
  const [userData, setUserData] = useState([]); // State to hold the fetched user data
  const [isLoading, setIsLoading] = useState(true); // Loading state for API call
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [values, setValues] = useState(userData);
  // const []

  let totalRecords = 100;
  const navigate = useNavigate();

  useEffect(() => {
    setValues(userData);
  }, [userData]);
  // Retrieve the token from localStorage
  const token = localStorage.getItem("authToken");

  const getApprovalUsers = async () => {
    try {
      const fetchedData = await getApprovalDetails();
      setUserData(fetchedData.data);
    } catch (error) {
      notification.error(error || "Error, please login again");
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch user data from the API when the component mounts
  useEffect(() => {
    getApprovalUsers();
  }, []); // Empty array to prevent infinite loop

  const handleApprove = async (userId) => {
    try {
      await approveUser({ id: userId, APPROVED_STATUS: "APPROVED" }, token);
      notification.success({ message: "User Approved Successfully" });
      // localStorage.setItem;
      // Update the local state with the new status
      setUserData((prevData) =>
        prevData.map((user) =>
          user.id === userId ? { ...user, APPROVED_STATUS: "APPROVED" } : user
        )
      );
    } catch (error) {
      notification.error({ message: "Error Approving User" });
    }
  };

  // Reject user API
  // const handleReject = async (userId) => {
  //   try {
  //     await rejectUser({ user_id: userId }, token);
  //     notification.success({ message: "User Rejected Successfully" });

  //     // Update the local state with the new status
  //     setUserData((prevData) =>
  //       prevData.map((user) =>
  //         user.id === userId ? { ...user, APPROVED_STATUS: "REJECTED" } : user
  //       )
  //     );
  //   } catch (error) {
  //     notification.error({ message: "Error Rejecting User" });
  //   }
  // };

  const handleReject = async (userId) => {
    if (!userId) {
      notification.error({ message: "Invalid User ID" });
      return;
    }

    // setLoading(true); // Set loading state to true

    try {
      const response = await rejectUser({ id: userId }, token);
      if (response && response.success) {
        notification.success({ message: "User Rejected Successfully" });

        // Update the local state with the new status
        setUserData((prevData) =>
          prevData.map((user) =>
            user.id === userId ? { ...user, APPROVED_STATUS: "REJECTED" } : user
          )
        );
      } else {
        notification.error({ message: "Failed to reject user" });
      }
    } catch (error) {
      // console.error("Error rejecting user:", error); // Log the error for debugging
      notification.error({ message: "Error Rejecting User" });
    } finally {
      // setLoading(false); // Reset loading state
    }
  };
  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "SrNo",
      render: (text, record, index) => index + 1,
      width: "5%",
    },
    {
      title: "Name",
      dataIndex: "NAME",
      width: "10%",
    },
    {
      title: "Designation",
      dataIndex: "Designation",
      width: "10%",
    },
    {
      title: "Requested Date",
      dataIndex: "reqDate", // Assuming this field contains the requested date
      width: "10%",
      render: (reqDate) => {
        if (!reqDate) return "No Date";
        const dateObj = new Date(localStorage.getItem("reqDate"));
        return dateObj.toLocaleDateString(); // Formats date to MM/DD/YYYY
      },
    },
    {
      title: "Approved Date",
      dataIndex: "approvedDate", // Assuming this field contains the approved date
      width: "10%",
      render: (approvedDate) => {
        if (!approvedDate) return "Not Approved";
        const dateObj = new Date(approvedDate);
        return dateObj.toLocaleDateString(); // Formats date to MM/DD/YYYY
      },
    },
    {
      title: "Status",
      dataIndex: "APPROVED_STATUS",
      render: (text) => (
        <div className="dropdown action-label">
          <Link
            className="btn btn-white btn-sm btn-rounded"
            to="#"
            style={{ cursor: "default" }}
          >
            <i
              className={
                (text === "PENDING" && "far fa-dot-circle text-warning") ||
                (text === "APPROVED" && "far fa-dot-circle text-success") ||
                (text === "REJECTED" && "far fa-dot-circle text-danger")
              }
            />
            {text}
          </Link>
        </div>
      ),
      width: "10%",
    },
    {
      title: "Action",
      render: (text, record) => (
        <div className="dropdown dropdown-action">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link
              className="dropdown-item"
              to="#"
              onClick={() => handleApprove(record.id)}
            >
              <i className="fa fa-check m-r-5" /> Approve
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              onClick={() => handleReject(record.id)}
            >
              <i className="fa fa-trash-alt m-r-5" /> Reject
            </Link>
          </div>
        </div>
      ),
      width: "10%",
    },
  ];

  const renderPlaceholder = () => (
    <>
      <p className="placeholder-glow">
        <span className="placeholder col-12" />
      </p>
      <p className="placeholder-wave mb-0">
        <span className="placeholder col-12" />
      </p>
    </>
  );

  const placeholderData = new Array(5).fill({});

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <Table
          dataSource={
            isLoading ? placeholderData : values?.length > 0 ? values : []
          }
          className="table-striped"
          // columns={columns}
          columns={
            isLoading
              ? columns.map((col) => ({
                  ...col,
                  render: () => renderPlaceholder(), // Render placeholder for all columns
                }))
              : columns
          }
          // dataSource={userData}
          rowKey={(record) => record.id}
          loading={isLoading} // Show loading indicator while fetching data
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: totalRecords,
            className: "pointer",
            onChange: (page, pageSize) => {
              setCurrentPage(page);
              setPageSize(pageSize);
            },
          }}
        />
      </div>
    </div>
  );
};

export default UserApproval;
