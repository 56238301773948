import React, { useState, useEffect, useMemo } from "react";
import Select from "react-select";
import * as XLSX from "xlsx";
import axios from "axios"; // Import axios for API calls
import { useForm } from "react-hook-form";
import { getacidDataCL, getfeedercode, getTableDataCLGCFA, submitChangeConsumerData } from "../../../../services/otherServices";
import loadingGif  from '../../../../assets/img/loading.gif';
import '../../Pages/ConsumerLinking.css';

const Report = () => {
    const title="Feeder";
    const title1="Change Consumer's Feeder"
  
  const [toggle, setToggle] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false); // Loading state for showing the GIF
  const [errors, setErrors] = useState({}); // State for tracking errors
  const [isLoading, setIsLoading] = useState(false); // Loading state for API call

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
        cursor: "pointer !important",
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999, // Increase the z-index of the dropdown menu
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999, // Increase z-index for the menu portal
    }),
  };

  const [accountId, setAccountId] = useState(""); // State to track Account Id input
  const [zone, setZone] = useState(null);
  const [circle, setCircle] = useState(null);
  const [division, setDivision] = useState(null);
  const [subDivision, setSubDivision] = useState(null);
  const [subStation, setSubStation] = useState(null);
  const [feeder, setFeeder] = useState(null);
  const [Fatchfeeder, setFatchFeeder] = useState(null);
  const [FatchfeederOptions, setFatchFeederOptions] = useState([]); // Holds the options for the select dropdown
  const [address, setAddress] = useState("");
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [ac_id, setacid] = useState("");
  const [name, setname] = useState("");
  const [area, setarea] = useState("");

  const { setValue } = useForm();
  const [showTable, setShowTable] = useState(false); // State to control table visibility
  

   // Function to fetch consumer details from the API based on Account Id
   const fetchConsumerDetails = async () => {
    setLoading(true); // Show loading spinner
    try {
        console.log("Fetching consumer details...");

        // Fetch consumer data from the API (getTableDataCLGCFA())
        const response = await getacidDataCL(accountId);
        const fetchedTableData = response.data;
        console.log("Fetched consumer details:", fetchedTableData);

        if (fetchedTableData && fetchedTableData.length > 0) {
            const consumer = fetchedTableData[0]; // Assuming the data is an array and accessing the first item

            // Update form fields with the consumer details
            setZone(consumer.ZONE || "N/A");
            setCircle(consumer.CIRCLE || "N/A");
            setDivision(consumer.DIVISION || "N/A");
            setSubDivision(consumer.SUB_DIVISION || "N/A");
            setSubStation(consumer.SUB_STATION || "N/A");
            setacid(consumer.AC_ID || "N/A");
            setname(consumer.NAME || "N/A");
            setAddress(consumer.ADDRESS || "N/A");
            setLat(consumer.LAT || "N/A");
            setLng(consumer.LNG || "N/A");
            setarea(consumer.SUB_DIVISION || "N/A");
            setFeeder(consumer.FEEDER_CODE || "N/A");

            console.log("Consumer details populated in fields:", consumer);
        } else {
            // Clear form fields when no data is found
            setZone("");
            setCircle("");
            setDivision("");
            setSubDivision("");
            setSubStation("");
            setacid("");
            setname("");
            setAddress("");
            setLat("");
            setLng("");
            setarea("");
            setFeeder("");
            alert("No consumer data found");
            console.error("No consumer data found.");
        }
    } catch (error) {
        console.error("Error fetching consumer details:", error);
    } finally {
        setLoading(false); // Hide loading spinner
    }
};

// Function to fetch consumer details from the API based on Account Id
const fetchfeedercodeDetails = async () => {
    setLoading(true); // Show loading spinner
    try {
        console.log("Fetching consumer details...");

        // Fetch consumer data from the API (getTableDataCLGCFA())
        const response = await getfeedercode(accountId);
        const fetchedTableData1 = response.data;
        console.log("Fetched consumer details:", fetchedTableData1);

        if (fetchedTableData1 && fetchedTableData1.length > 0) {
             // Map the fetched data to format options for the select dropdown
             const feederOptions = fetchedTableData1.map(consumer => ({
                value: consumer.FEEDER_CODE, // Set FEEDER_CODE as the value
                label: `${consumer.FEEDER_CODE} - ${consumer.FEEDER}` // Display both FEEDER_CODE and FEEDER
            }));

            // Update form field with the formatted options
            setFatchFeederOptions(feederOptions);

            console.log("details populated in fields:", feederOptions);
        } else {
            setFatchFeederOptions([]);
            console.error("No  data found.");
        }
    } catch (error) {
        console.error("Error fetching  details:", error);
    } finally {
        setLoading(false); // Hide loading spinner
    }
};
  

    const handleAccountIdSearch = () => {
        if (!accountId) {
            alert("Please enter an Account Id");
            return;
        }
        fetchConsumerDetails();
        fetchfeedercodeDetails();
    };



  // Function to handle the "Show Report" button click
  // const handleToggle = async () => {

  
  //   setLoading(true); // Show loading GIF
  //   setIsLoading(true);
    
  //     setToggle(!toggle);
      
    
    
  //   setLoading(false); // Hide loading GIF after the operation
  //   setIsLoading(false);
  // };

  // Function to handle form submission
//   const handleSubmit = async () => {
//     try {
//         setIsLoading(true); // Show loading

//         // Prepare data to be submitted
//         if (!accountId) {
//           alert("Please Enter Account Id");
//           return;
//         }
//         if (!Fatchfeeder || !Fatchfeeder.value) { // Check if feeder is not selected
//             alert("Please Select Any Feeder");
//             return;
//         }
//         // const formData = {
//         //     accountId,
//         //     feeder: Fatchfeeder ? Fatchfeeder.value : null, // Get the value of selected feeder
//         // };

//         //alert(`Account Id: ${accountId}`);
//         //alert(`Selected Feeder: ${Fatchfeeder.value}`);
//         //Send data to the API
//         const response = await submitChangeConsumerData(accountId,Fatchfeeder.value);
//         if (response.status === 200) {
//             alert("Data successfully submitted");
//             window.location.reload(); // Refresh page after success
//         }
//     } catch (error) {
//         console.error("Error submitting data:", error);
//         alert("Error submitting data");
//     } finally {
//         setIsLoading(false); // Hide loading
//     }
// };

const handleSubmit = async (e) => {
  e.preventDefault(); // Prevent form reload
  try {
      setIsLoading(true); // Show loading

      // Prepare data to be submitted
      if (!accountId) {
          alert("Please Enter Account Id");
          return;
      }
      if (!Fatchfeeder || !Fatchfeeder.value) { // Check if feeder is not selected
          alert("Please Select Any Feeder");
          return;
      }

      // Send data to the API
      const response = await submitChangeConsumerData(accountId, Fatchfeeder.value);
      
      //if (response.status === 200 && response.data.Success) {
        if (response.Success) {
        alert(response.Message); // Show success message from API
        window.location.reload(); // Reload page
    } else {
        alert(`Error: ${response.Message}`); // Show error message from API
    }
  } catch (error) {
      console.error("Error submitting data:", error);
      alert("Error submitting data");
  } finally {
      setIsLoading(false); // Hide loading
  }
};

  
  return (
    <div>
      <div className="main-wrapper">
        <div className="page-wrapper">
          <div className="content container-fluid col-md-12 d-flex justify-content-center">
            <div className="row w-100 h-100">
           
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header relative">
                      <h2 className="card-title mb-0">
                        {title1}
                      </h2>
                    </div>
                    <div className="card-body">
                      <form action="#">
                        <div className="row">
                          {/* <div className="col navbar-nav-scroll"> */}
                           
                            <div className="row align-items-center">
                                    <div className="col-md-6 mb-5">
                                        <label htmlFor="accountId" className="form-label">Account Id</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="accountId"
                                            value={accountId}
                                            onChange={(e) => setAccountId(e.target.value)}
                                            placeholder="Enter Account Id"
                                            required=""
                                        />
                                    </div>
                                    <div className="col-md-3 mb-3 d-flex justify-content-start">
                                        <button
                                            className="btn btn-primary ms-3"
                                            onClick={handleAccountIdSearch}
                                            disabled={loading || isLoading} // Disable button while loading
                                        >
                                            {/* {isLoading ? "Loading..." : "Search"} */}
                                            Search
                                        </button>
                                    </div>
                                </div>

                            <div className="input-block mb-3 row"></div>
                            <div className="row">
                            <h4 className="card-title">Consumer Details</h4>
                                            <div className="col-xl-6">
                                                
                                                <div className="input-block mb-3 row">
                                                <label className="col-lg-3 col-form-label label-left-space">
                                                    Zone
                                                </label>
                                                <div className="col-lg-8">
                                                    <input type="text" className="form-control" value={zone || ""} readOnly />
                                                </div>
                                                </div>
                                                <div className="input-block mb-3 row">
                                                <label className="col-lg-3 col-form-label label-left-space">Division</label>
                                                <div className="col-lg-8">
                                                    <input type="text" className="form-control" value={division || ""} readOnly/>
                                                </div>
                                                </div>
                                                <div className="input-block mb-3 row">
                                                <label className="col-lg-3 col-form-label label-left-space">
                                                    Sub Station
                                                </label>
                                                <div className="col-lg-8">
                                                    <input type="text" className="form-control" value={subStation || ""} readOnly/>
                                                </div>
                                                </div>
                                                <div className="input-block mb-3 row">
                                                <label className="col-lg-3 col-form-label label-left-space">
                                                    Account Id 
                                                </label>
                                                <div className="col-lg-8">
                                                    <input type="text" className="form-control" value={ac_id || ""} readOnly/>
                                                </div>
                                                </div>
                                                <div className="input-block mb-3 row">
                                                <label className="col-lg-3 col-form-label label-left-space">
                                                    Address
                                                </label>
                                                <div className="col-lg-8">
                                                    <input type="text" className="form-control" value={address || ""} readOnly/>
                                                </div>
                                                </div>
                                                <div className="input-block mb-3 row">
                                                <label className="col-lg-3 col-form-label label-left-space">
                                                   Latitute
                                                </label>
                                                <div className="col-lg-8">
                                                    <input type="text" className="form-control" value={lat || ""} readOnly/>
                                                </div>
                                                </div>
                                                <div className="input-block mb-3 row">
                                                <label className="col-lg-3 col-form-label label-left-space">
                                                    Select New Feeder
                                                </label>
                                                <div className="col-lg-8">
                                                    <Select
                                                    //id="feeder"
                                                    options={FatchfeederOptions}
                                                    value={Fatchfeeder} // Display the selected option
                                                    onChange={setFatchFeeder}
                                                    isLoading={loading} // Show loading spinner while options are loading
                                                    placeholder="Select Feeder"
                                                    styles={customStyles}
                                                    menuPortalTarget={document.body} // Ensures dropdown appears above other elements
                                                />
                                                </div>
                                                </div>
                                
                                            </div>
                                            <div className="col-xl-6">
                                            
                                                <div className="input-block mb-3 row">
                                                <label className="col-lg-3 col-form-label">
                                                   Circle
                                                </label>
                                                <div className="col-lg-9">
                                                    <input type="text" className="form-control" value={circle || ""} readOnly/>
                                                </div>
                                                </div>
                                                <div className="input-block mb-3 row">
                                                <label className="col-lg-3 col-form-label">
                                                    Sub Division
                                                </label>
                                                <div className="col-lg-9">
                                                    <input type="text" className="form-control" value={subDivision || ""} readOnly/>
                                                </div>
                                                </div>
                                                <div className="input-block mb-3 row">
                                                <label className="col-lg-3 col-form-label">
                                                    Feeder
                                                </label>
                                                <div className="col-lg-9">
                                                    <input type="text" className="form-control" value={feeder || ""} readOnly/>
                                                </div>
                                                </div>
                                                <div className="input-block mb-3 row">
                                                <label className="col-lg-3 col-form-label">
                                                    Name
                                                </label>
                                                <div className="col-lg-9">
                                                    <input type="text" className="form-control" value={name || ""} readOnly/>
                                                </div>
                                                </div>
                                                <div className="input-block mb-3 row">
                                                <label className="col-lg-3 col-form-label">
                                                    Area
                                                </label>
                                                <div className="col-lg-9">
                                                    <input type="text" className="form-control" value={area || ""} readOnly/>
                                                </div>
                                                </div>
                                                <div className="input-block mb-3 row">
                                                <label className="col-lg-3 col-form-label">
                                                    Longitute
                                                </label>
                                                <div className="col-lg-9">
                                                    <input type="text" className="form-control" value={lng || ""} readOnly/>
                                                </div>
                                                </div>
                                              
                                            </div>
                                         
                                            </div>
                                           
                                {/* <div className="col-md-12">
                                <div className="input-block mb-2">
                                    <div className="message-inner">
                                    <form
                                        id="js-upload-form"
                                        className="w-100 col-md-12"
                                    >
                                    </form>
                                    </div>
                                </div>
                                </div> */}
                          {/* </div> */}
                        </div>
                      </form>

                      <div className="btn-list d-flex justify-content-center">
                        <button
                          className="btn btn-primary me-1"
                          // onClick={handleDownload}
                          onClick={handleSubmit}
                          disabled={loading || isLoading} // Disable button while loading
                        >
                          {isLoading ? "Loading..." : "Submit"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              
            </div>
          </div>
          {loading && (
        <div className="loading-overlay">
          <img src={loadingGif} alt="Loading..." />
        </div>
      )}
        </div>
      </div>
    </div>
    
  );
};

export default Report;
