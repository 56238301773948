import React from "react";
import FeederInputData from "./FeederInputData";
import Header from "../../../layout/Header";
import Sidebar from "../../../layout/Sidebar";
import { Outlet } from "react-router-dom";

const FeederInputDataView = () => {
  return (
    <>
      <Header />
      <Sidebar />
      <Outlet />
      <FeederInputData />
    </>
  );
};

export default FeederInputDataView;
