import React from "react";

const IframesPage = () => {
  return (
    <div className="main-wrapper">
        <div className="page-wrapper">
          <div className="content container-fluid col-md-12 d-flex justify-content-center">
            <div className="row w-100 h-100">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  
                  {/* Flex container to display iframes horizontally */}
                  <div style={{ display: 'flex', flexDirection: 'row', gap: '15px', height: '80vh' }}>
                    
                    {/* First iframe section */}
                    <div style={{ width: '50%'}}>
                      <h2>Before Linking</h2>
                      <iframe
                        title="iframe1"
                        src="/before_linking.html"  // Replace with actual source
                        width="100%"  // Adjusted to take full width of its container
                        height="95%"  // Full height
                        frameBorder="0"
                        style={{ border: "none" }}
                      ></iframe>
                    </div>

                    {/* Second iframe section */}
                    <div style={{ width: '50%' }}>
                      <h2>After Linking</h2>
                      <iframe
                        title="iframe2"
                        src="/after_linking.html"  // Replace with actual source
                        width="100%"  // Adjusted to take full width of its container
                        height="95%"  // Full height
                        frameBorder="0"
                        style={{ border: "none" }}
                      ></iframe>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IframesPage;
