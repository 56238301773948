import React from "react";
import ImportData from "../../Pages/ImportData";

const CollectionData = () => {
  return (
    <div>
      <ImportData title="Collection" />
    </div>
  );
};

export default CollectionData;
