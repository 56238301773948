import React from "react";
import DivisionWiseReport from "./DivisionWiseReport";

const DivisionWiseReportView = () => {
  return (
    <div>
      <DivisionWiseReport />
    </div>
  );
};

export default DivisionWiseReportView;
