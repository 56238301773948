import React, { useState, useEffect, useMemo } from "react";
import Select from "react-select";
import ReportDropdowns from "./ReportDropdownsConsumerLinking";
import * as XLSX from "xlsx";

import axios from "axios"; // Import axios for API calls
import ReportTable from "./ReportTableFeederWise";
import { useForm } from "react-hook-form";
import { Tooltip } from "antd"; // Import Tooltip from Ant Design
import { InfoCircleOutlined } from "@ant-design/icons"; // Ant Design icon for "i"
//import ConsumerLinkingTableGCFA from "./ConsumerLinkingTableGCFA"
import { getTableDataCLGCFA } from "../../../services/otherServices";
import loadingGif  from '../../../assets/img/loading.gif';
import './ConsumerLinking.css'; // Import the CSS file
import ReportTableFeederWise from "./ReportTableFeederWise";
import ConsumerLinkingMap from "./ConsumerLinkingMap"
import MapComponent from "../ConsumerLinking/RestUNConsumer/MapComponent";

import html2canvas from 'html2canvas';

const Report = ({ title,title1}) => {

  const months = [
    // { label: "January", value: 1 },
    // { label: "February", value: 2 },
    // { label: "March", value: 3 },
    // { label: "April", value: 4 },
    // { label: "May", value: 5 },
    { label: "June", value: 6 },
    { label: "July", value: 7 },
    { label: "August", value: 8 },
    // { label: "September", value: 9 },
    // { label: "October", value: 10 },
    // { label: "November", value: 11 },
    // { label: "December", value: 12 },
  ];

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  const year = [{ label: "2024", value: "2024" }];
  
  const [toggle, setToggle] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false); // Loading state for showing the GIF
  const [errors, setErrors] = useState({}); // State for tracking errors
  const [isLoading, setIsLoading] = useState(false); // Loading state for API call

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
        cursor: "pointer !important",
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999, // Increase the z-index of the dropdown menu
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999, // Increase z-index for the menu portal
    }),
  };

  const [filteredMonths, setFilteredMonths] = useState([]);
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null); // Track selected month
  const [selectedYear, setSelectedYear] = useState(null); // Track selected year
  const [feeder, setFeeder] = useState(null);
  const [zone, setZone] = useState(null);
  const [circle, setCircle] = useState(null);
  const [division, setDivision] = useState(null);
  const [subDivision, setSubDivision] = useState(null);
  const [subStation, setSubStation] = useState(null);
  const { setValue } = useForm();
  const [showTable, setShowTable] = useState(false); // State to control table visibility
  

  useEffect(() => {
    if (currentYear === 2024) {
      const validMonths = months.filter((month) => month.value <= currentMonth);
      setFilteredMonths(validMonths);
    } else {
      setFilteredMonths(months);
    }
  }, [currentYear]);

  useEffect(() => {
    setValue("report_month", localStorage.getItem("report_month"));
    setValue("report_year", localStorage.getItem("report_year"));
  }, [setValue]);

  const report_month = localStorage.getItem("report_month") || "";
  const report_year = localStorage.getItem("report_year") || "";


  const validateForm = () => {
    const newErrors = {};

    if (title1 === "Rest UnLink Consumer") {
      if (!selectedMonth) newErrors.selectedMonth = "Month is required";
      if (!selectedYear) newErrors.selectedYear = "Year is required";
      if (!subStation) newErrors.subStation = "Sub Station is required";
      if (!feeder) newErrors.feeder = "Feeder is required";
    }
    if (!zone) newErrors.zone = "Zone is required";
    if (!circle) newErrors.circle = "Circle is required";
    if (!division) newErrors.division = "Division is required";
    if (!subDivision) newErrors.subDivision = "Sub Division is required";
    // if (!subStation) newErrors.subStation = "Sub Station is required";
    // if (!feeder) newErrors.feeder = "Feeder is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // If there are no errors, return true
  };


// Function to handle CSV export
const exportToCSV = (data) => {
    const csvData = data.map((data, index) => ({
        SR_NO: index + 1,
        ZONE: data.ZONE,
        CIRCLE: data.CIRCLE,
        DIVISION: data.DIVISION,
        SUB_DIVISION: data.SUB_DIVISION,
        AC_ID: data.AC_ID,
        NAME: data.NAME,
        ADDRESS: data.ADDRESS,
        LAT: data.LAT,
        LNG: data.LNG,
      // Add more columns as needed
    }));

    const worksheet = XLSX.utils.json_to_sheet(csvData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Report");

    // Export the CSV file
    XLSX.writeFile(workbook, "UN-Linked Consumers Report.csv");
  };

  const [tableData, setTableData] = useState([]);

  const tableDetails = async () => {
    try {
      const fetchedTableData = await getTableDataCLGCFA(
        );
      setTableData(fetchedTableData?.data);
      return fetchedTableData?.data;
   
    } catch (error) {
      console.error("Error fetching data:", error);
    } 
  };

  // if(title1==='Before Linking (Map View)')
  // {
    
  // }else if(title1==='After Linking (Map View)')
  // {

  // }else{

  // }
  

  // Function to handle the "Show Report" button click
  const handleToggle = async () => {

   // validateForm()
    
   const isValid = validateForm();
  if (!isValid) {
    alert("Please Select Reuired Field");
    return; // Stop further execution if form is invalid
  }

    setLoading(true); // Show loading GIF
    setIsLoading(true);
    
    // Export to CSV
    //exportToCSV(reportData);
    // Toggle to display report in UI (if needed)
    //setToggle(!toggle);

    if (title1 === "Rest UnLink Consumer") {
      // If title is "a", export to CSV
        //setShowTable(true);
        //const reportData = await tableDetails();
        // If title is not "a", show table data
        //exportToCSV(reportData);

    } else {
      // Fetch data from the API
      setToggle(!toggle);
      
    }
    
    setLoading(false); // Hide loading GIF after the operation
    setIsLoading(false);
  };
  
  return (
    <div>
      <div className="main-wrapper">
        <div className="page-wrapper">
          <div className="content container-fluid col-md-12 d-flex justify-content-center">
            <div className="row w-100 h-100">
            {!toggle ? (
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header relative">
                      <h2 className="card-title mb-0">
                        {title1}
                      </h2>
                    </div>
                    <div className="card-body">
                      <form action="#">
                        <div className="row">
                          <div className="col navbar-nav-scroll">
                            <div className="row">
                            </div>
                            {title1 === "Rest UnLink Consumer" && (
                            <div className="row">
                              <div className="col-md-6">
                                <div className="input-block mb-3">
                                  <label className="col-form-label">
                                    <h4>Billing Month</h4>
                                  </label>
                                  <div className="col-md-12">
                                    <Select
                                      name="report_month"
                                      options={filteredMonths}
                                      placeholder="-- Select Month --"
                                      styles={customStyles}
                                      className="pointer"
                                      onChange={(selected) =>
                                        // setSelectedMonth(selected)
                                        {
                                          setSelectedMonth(selected?.value);
                                          setValue("report_month",selected?.value);
                                          localStorage.setItem("report_month",selected?.value);
                                          
                                          // Clear error for month if it exists
                                          setErrors((prevErrors) => {
                                            const newErrors = { ...prevErrors };
                                            delete newErrors.selectedMonth; // Remove the error for selectedMonth
                                            return newErrors; // Return the updated errors
                                          });
                                        }
                                      }
                                      menuPortalTarget={document.body} // Render the dropdown in a portal to the body
                                    />
                                  </div>
                                  {errors.selectedMonth && <div style={{ color: 'red' }}>Month is required</div>}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="input-block mb-3">
                                  <label className="col-form-label">
                                    <h4>Billing Year</h4>
                                  </label>
                                  <div className="col-md-12">
                                    <Select
                                      name="report_year"
                                      options={year}
                                      placeholder="-- Select Year --"
                                      styles={customStyles}
                                      onChange={(selected) =>
                                        
                                        {
                                          setSelectedYear(selected?.value);
                                          setValue(
                                            "report_year",
                                            selected?.value
                                          );
                                          localStorage.setItem(
                                            "report_year",
                                            selected?.value
                                          );
                                          // Clear error for year if it exists
                                        setErrors((prevErrors) => {
                                          const newErrors = { ...prevErrors };
                                          delete newErrors.selectedYear; // Remove the error for selectedYear
                                          return newErrors; // Return the updated errors
                                        });
                                        }
                                      }
                                      defaultValue={
                                        year.find(
                                          (y) => y.value === +report_year
                                        ) || null
                                      }
                                      menuPortalTarget={document.body}
                                    />
                                  </div>
                                  {errors.selectedYear && <div style={{ color: 'red' }}>Year is required</div>}
                                </div>
                              </div>
                            </div>
                          )}
                            <div className="col-md-12">
                              <div className="input-block mb-2">
                                <div className="message-inner">
                                  <form
                                    id="js-upload-form"
                                    className="w-100 col-md-12"
                                  >
                                    <div className="col">
                                      <div className="row">
                                        <ReportDropdowns
                                         errors={errors}
                                         setErrors={setErrors}
                                          zone={zone}
                                          circle={circle}
                                          division={division}
                                          subDivision={subDivision}
                                          subStation={subStation}
                                          feeder={feeder}
                                          setFeeder={setFeeder}
                                          setZone={setZone}
                                          setCircle={setCircle}
                                          setDivision={setDivision}
                                          setSubDivision={setSubDivision}
                                          setSubStation={setSubStation}
                                          title={title}
                                          
                                        />
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>

                      <div className="btn-list d-flex justify-content-center">
                        <button
                          className="btn btn-primary me-1"
                          // onClick={handleDownload}
                          onClick={handleToggle}
                          disabled={loading || isLoading} // Disable button while loading
                        >
                           {/* Dynamically render button content based on title1 */}
                              {isLoading ? "Loading..." : 
                                title1 === 'Before Linking (Map View)' ? (
                                  <>
                                    <i className="fa fa-map-marker"></i> View Un-Linked Consumer On Map
                                  </>
                                ) : title1 === 'After Linking (Map View)' ? (
                                  <>
                                    <i className="fa fa-map-marker"></i> View Linked Consumer On Map
                                  </>
                                ) : (
                                  <>
                                    <i className="fa fa-download"></i> <a
                                                            href="/UN-Linked Consumers Report.csv"
                                                            download="UN-Linked Consumers Report.csv"
                                                          >
                                                            Download
                                                          </a>
                                  </>
                                )
                              }
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {title === "Sub Division" && title1 === "Before Linking (Map View)" ? (
                      <>
                        {/* Buttons for downloading CSV and map */}
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '1%' }}>
                          {/* Heading and buttons in the same line */}
                          
                          <h2 style={{ marginRight: 'auto' }}>Before Linking (Map View)</h2> 

                          <button
                          className="btn btn-primary me-1"
                            onClick={() => {
                              const link = document.createElement('a');
                              link.href = '/unlinked_data.csv'; // Path to the CSV file in your public folder
                              link.download = 'Plotted Consumers Before Linking.csv';
                              link.click();
                            }}
                            
                          >
                            Download Report
                          </button>

                          {/* Button to download map 
                          <button
                          className="btn btn-primary me-1"
                            // onClick={() => {
                            //   const iframe = document.querySelector('iframe');
                            //   const iframeWindow = iframe.contentWindow;

                            //   iframeWindow.html2canvas(iframeWindow.document.body).then((canvas) => {
                            //     const link = document.createElement('a');
                            //     link.href = canvas.toDataURL('image/png');
                            //     link.download = 'feeder_map.png';
                            //     link.click();
                            //   });
                            // }}
                            
                          >
                            Download Map
                          </button>*/}
                        </div>

                        {/* Feeder Map */}
                        
                        <iframe
                          src="/before_linking.html"
                          title="Feeder Map"
                          style={{ marginTop: '2%', width: '100%', height: '70vh', border: 'none' }}
                        ></iframe>
                      </>
                    ) : title === "Sub Division" && title1 === "After Linking (Map View)" ? (
                      <>
                         {/* Buttons for downloading CSV and map */}
                         <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '1%' }}>
                          {/* Heading and buttons in the same line */}
                          
                          <h2 style={{ marginRight: 'auto' }}>After Linking (Map View)</h2>
                          <button
                          className="btn btn-primary me-1"
                            onClick={() => {
                              const link = document.createElement('a');
                              link.href = '/Linked_data.csv'; // Path to the CSV file in your public folder
                              link.download = 'Plotted Consumers After Linking.csv';
                              link.click();
                            }}
                            
                          >
                            Download Report
                          </button>

                          {/* Button to download map 
                          <button
                          className="btn btn-primary me-1"
                            // onClick={() => {
                            //   const iframe = document.querySelector('iframe');
                            //   const iframeWindow = iframe.contentWindow;

                            //   iframeWindow.html2canvas(iframeWindow.document.body).then((canvas) => {
                            //     const link = document.createElement('a');
                            //     link.href = canvas.toDataURL('image/png');
                            //     link.download = 'feeder_map.png';
                            //     link.click();
                            //   });
                            // }}
                            
                          >
                            Download Map
                          </button>*/}
                        </div>

                        {/* Feeder Map */}
                        <iframe
                          src="/after_linking.html"
                          title="Feeder Map"
                          style={{ marginTop: '2%', width: '100%', height: '70vh', border: 'none' }}
                        ></iframe>
                      </>
                    ): (
                      ""
                    )}
                 
                </>
              
              )}
            </div>
          </div>
          {loading && (
        <div className="loading-overlay">
          <img src={loadingGif} alt="Loading..." />
        </div>
      )}
        </div>
      </div>
    </div>
    
  );
};

export default Report;
