import React from "react";
import Report from "../../Pages/ConsumerLinking";

const GeoCordinateFromAddressReport = () => {
  return (
    <div>
      <Report title="Sub Division" title1="After Linking (Map View)"/>
    </div>
  );
};      

export default GeoCordinateFromAddressReport;
