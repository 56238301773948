import React from "react";
import Report from "../../Pages/Report";

const ZoneWiseReport = () => {
  return (
    <div>
      <Report title="Zone" />
    </div>
  );
};

export default ZoneWiseReport;
