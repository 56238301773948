import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Applogo, sailogo1 } from "../../../Routes/ImagePath";
import { useSelector } from "react-redux";
// import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { sendOtp, validateOtp } from "../../../services/otherServices";
import { Button, Toast, ToastContainer } from "react-bootstrap";

// import * as Yup from "yup";
import * as yup from "yup";

import OtpInput from "react-otp-input";
import { useFormik } from "formik";
import { notification } from "antd";

const schema = yup.object({
  OTP: yup
    .string()
    .matches(/^\d{6}$/, "OTP must be 4 digits number")
    .required("Kindly enter OTP")
    .trim(),
});

const Otp = () => {
  // Toast state management
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState("success"); // "success" or "danger"
  const handleToastClose = () => setShowToast(false);
  const [errorMessage, setErrorMessage] = useState("");

  // const { signIn } = useAuth();
  const [passData, setPassData] = useState({});
  const [isOtpExpired, setIsOtpExpired] = useState(false);
  const [otpSentTime, setOtpSendTime] = useState();

  const token = useSelector((state) => state.auth);

  const navigate = useNavigate();
  const location = useLocation();
  const otpData = location?.state;
  const [isDisabled, setIsDisabled] = useState(true);

  const { formData } = location.state || {};

  const onSubmit = (values) => {
    if (handleOtpValidation(values)) {
      setToastMessage("Password updated successfully");
      setToastVariant("success");
      showToast(true);
      setTimeout(() => {
        navigate("/");
      }, 3000);
    } else {
      setErrorMessage("An error occurred during upload.");
      setToastMessage("Error uploading data. Please try again.");
      setToastVariant("danger");
      setShowToast(true);
    }

    // navigate("/login");
  };
  const handleOtpValidation = async (values) => {
    try {
      const body = {
        USER_ID: otpData.userId,
        enteredOtp: Number(values.OTP),
        MOBILE_NO: otpData.mobileNumber,
        EMAIL: otpData.email,
        RESET_OPTION: otpData.resetOption,
        SECURITY_ANSWER: "",
        NEW_PASSWORD: otpData.newPassword,
      };
      // console.log("body otp validation", body);
      const response = await validateOtp(body);
      if (response.Is_OTP_EXPIRED === "true") {
        alert("OTP EXPIRED");
        setIsDisabled(false);
      } else if (response.Success === true) {
        setToastMessage("Password updated successfully");
        setToastVariant("success");
        showToast(true);
        setTimeout(() => {
          navigate("/");
        }, 3000);
        // setTimeout(() => {
        //   alert("Password changed successfully");
        //   navigate("/sign-in");
        // }, 3000);
      } else if (response.Success === false) {
        setErrorMessage("An error occurred during upload.");
        setToastMessage("Error uploading data. Please try again.");
        setToastVariant("danger");
        setShowToast(true);
      }
    } catch (error) {
      // notification.error(error);
      setErrorMessage("An error occurred during upload.");
      setToastMessage("Error uploading data. Please try again.");
      setToastVariant("danger");
      setShowToast(true);
    }
  };

  const resendOtp = async () => {
    const formData = {
      USER_ID: localStorage.getItem("otpUserId"),
      MOBILE_NO: localStorage.getItem("otpMobile"),
      EMAIL: localStorage.getItem("otpEmail"),
      METHOD: localStorage.getItem("otpMethod"),
      SECURITY_ANSWER: "",
    };

    // console.log("formDataformData", formData);
    try {
      let otpDetails = await sendOtp(formData);
      // console.log("otpDetails", otpDetails);
      if (otpDetails?.data) {
        alert("Otp Resend Successfully");
        // navigate("/otp", {
        //   state: {
        //     userId: formData.USER_ID,
        //     mobileNumber: formData.MOBILE_NO, // Pass the mobile number
        //     email: formData.EMAIL,
        //     resetOption: formData.RESET_OPTION,
        //     // newPassword: formData.NEW_PASSWORD, // Pass the new password
        //   },
        // });
      } else {
        alert(otpDetails?.Message || "Something went wrong, please try again.");
      }
    } catch (error) {
      alert("There was an error processing your request.");
    }
  };
  const generateOtp = async (formData) => {
    try {
      const body = {
        MOBILE_NO: formData.MOBILE_NO,
      };

      const passData = await sendOtp(formData);

      setPassData(passData);
    } catch (error) {
      notification.error(error);
    }
  };

  const initialValues = {
    OTP: "",
  };
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: schema,
  });

  return (
    <>
      <ToastContainer
        position="top-end"
        className="p-3"
        style={{ position: "fixed", top: 0, right: 0, zIndex: 9999 }} // Fixed positioning and zIndex added
      >
        <Toast
          onClose={handleToastClose}
          show={showToast}
          delay={3000}
          autohide
          bg={toastVariant} // Set background based on success or error
        >
          <Toast.Header>
            <strong className="me-auto">
              {toastVariant === "success" ? "Success" : "Error"}
            </strong>
            <small>just now</small>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
      <form onSubmit={formik.handleSubmit}>
        <div className="account-page">
          {/* Main Wrapper */}
          <div className="main-wrapper">
            <div className="account-content">
              <div className="container">
                {/* Account Logo */}
                <div className="account-logo">
                  {/* <Link to="/admin-dashboard"> */}
                  <Link to="/login">
                    <img src={sailogo1} alt="error while loading image" />
                  </Link>
                </div>
                {/* /Account Logo */}
                <div className="account-box">
                  <div className="account-wrapper">
                    <h3 className="account-title">OTP</h3>
                    <p className="account-subtitle">
                      Verification your account
                    </p>

                    <div className="otp-wrap  d-flex justify-content-center align-items-center">
                      <OtpInput
                        className="otp-input"
                        value={formik.values.OTP}
                        onChange={(otp) => {
                          formik.setFieldValue("OTP", otp);
                        }}
                        numInputs={6}
                        renderSeparator={<span>-</span>}
                        // isInputNum:tel
                        renderInput={({ inputType = "tel", ...props }) => (
                          <input
                            {...props}
                            style={{
                              width: "2.5rem",
                              height: "2.5rem",
                              margin: "0 0.5rem",
                              fontSize: "1.5rem",
                              borderRadius: "4px",
                              border: "1px solid #ccc",
                              textAlign: "center",
                            }}
                            type={inputType}
                          />
                        )}
                      />
                    </div>
                    {formik.errors.OTP && (
                      <div style={{ color: "red", textAlign: "center" }}>
                        {formik.errors.OTP}
                      </div>
                    )}
                    <div className="input-block mb-2 text-center">
                      <button
                        className="btn btn-primary account-btn"
                        type="submit"
                      >
                        Enter
                      </button>
                    </div>
                    <div className="account-footer">
                      <p>
                        Not yet received?{" "}
                        <Link to="/otp" onClick={() => resendOtp()}>
                          Resend OTP
                        </Link>
                      </p>
                    </div>
                    {/* /Account Form */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /Main Wrapper */}
        </div>
      </form>
    </>
  );
};

export default Otp;
// import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import { Applogo, sailogo1 } from "../../../Routes/ImagePath";
// import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import { useLocation } from "react-router-dom";
// import { sendOtp, validateOtp } from "../../../services/otherServices";
// import * as yup from "yup";
// import OtpInput from "react-otp-input";
// import { useFormik } from "formik";
// import { notification } from "antd"; // Already imported

// const schema = yup.object({
//   OTP: yup
//     .string()
//     .matches(/^\d{6}$/, "OTP must be 6 digits number")
//     .required("Kindly enter OTP")
//     .trim(),
// });

// const Otp = () => {
//   const [passData, setPassData] = useState({});
//   const [isOtpExpired, setIsOtpExpired] = useState(false);
//   const [otpSentTime, setOtpSendTime] = useState();

//   const token = useSelector((state) => state.auth);

//   const navigate = useNavigate();
//   const location = useLocation();
//   const otpData = location?.state;
//   const [isDisabled, setIsDisabled] = useState(true);

//   const { formData } = location.state || {};

//   const onSubmit = (values) => {
//     handleOtpValidation(values);
//   };

//   const handleOtpValidation = async (values) => {
//     try {
//       const body = {
//         USER_ID: otpData.userId,
//         enteredOtp: Number(values.OTP),
//         MOBILE_NO: otpData.mobileNumber,
//         EMAIL: otpData.email,
//         RESET_OPTION: otpData.resetOption,
//         SECURITY_ANSWER: "",
//         NEW_PASSWORD: otpData.newPassword,
//       };
//       const response = await validateOtp(body);
//       if (response.Is_OTP_EXPIRED === "true") {
//         alert("OTP EXPIRED");
//         setIsDisabled(false);
//       } else if (response.Success === true) {
//         // Show success toast notification
//         notification.success({
//           message: "Success",
//           description: "Password changed successfully",
//           duration: 3, // Notification will be displayed for 3 seconds
//         });

//         setTimeout(() => {
//           navigate("/sign-in");
//         }, 3000); // Redirect after 3 seconds
//       } else if (response.Success === false) {
//         alert(response.message);
//       }
//     } catch (error) {
//       notification.error({
//         message: "Error",
//         description: error.message || "Something went wrong",
//       });
//     }
//   };

//   const resendOtp = async () => {
//     const formData = {
//       USER_ID: localStorage.getItem("otpUserId"),
//       MOBILE_NO: localStorage.getItem("otpMobile"),
//       EMAIL: localStorage.getItem("otpEmail"),
//       METHOD: localStorage.getItem("otpMethod"),
//       SECURITY_ANSWER: "",
//     };
//     try {
//       let otpDetails = await sendOtp(formData);
//       if (otpDetails?.data) {
//         alert("Otp Resend Successfully");
//       } else {
//         alert(otpDetails?.Message || "Something went wrong, please try again.");
//       }
//     } catch (error) {
//       alert("There was an error processing your request.");
//     }
//   };

//   const generateOtp = async (formData) => {
//     try {
//       const passData = await sendOtp(formData);
//       setPassData(passData);
//     } catch (error) {
//       notification.error(error);
//     }
//   };

//   const initialValues = {
//     OTP: "",
//   };

//   const formik = useFormik({
//     initialValues,
//     onSubmit,
//     validationSchema: schema,
//   });

//   return (
//     <>
//       <form onSubmit={formik.handleSubmit}>
//         <div className="account-page">
//           <div className="main-wrapper">
//             <div className="account-content">
//               <div className="container">
//                 <div className="account-logo">
//                   <Link to="/login">
//                     <img src={sailogo1} alt="error while loading image" />
//                   </Link>
//                 </div>
//                 <div className="account-box">
//                   <div className="account-wrapper">
//                     <h3 className="account-title">OTP</h3>
//                     <p className="account-subtitle">
//                       Verification your account
//                     </p>

//                     <div className="otp-wrap d-flex justify-content-center align-items-center">
//                       <OtpInput
//                         className="otp-input"
//                         value={formik.values.OTP}
//                         onChange={(otp) => {
//                           formik.setFieldValue("OTP", otp);
//                         }}
//                         numInputs={6}
//                         renderSeparator={<span>-</span>}
//                         renderInput={({ inputType = "tel", ...props }) => (
//                           <input
//                             {...props}
//                             style={{
//                               width: "2.5rem",
//                               height: "2.5rem",
//                               margin: "0 0.5rem",
//                               fontSize: "1.5rem",
//                               borderRadius: "4px",
//                               border: "1px solid #ccc",
//                               textAlign: "center",
//                             }}
//                             type={inputType}
//                           />
//                         )}
//                       />
//                     </div>
//                     {formik.errors.OTP && (
//                       <div style={{ color: "red", textAlign: "center" }}>
//                         {formik.errors.OTP}
//                       </div>
//                     )}
//                     <div className="input-block mb-2 text-center">
//                       <button
//                         className="btn btn-primary account-btn"
//                         type="submit"
//                       >
//                         Enter
//                       </button>
//                     </div>
//                     <div className="account-footer">
//                       <p>
//                         Not yet received?{" "}
//                         <Link to="/otp" onClick={() => resendOtp()}>
//                           Resend OTP
//                         </Link>
//                       </p>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </form>
//     </>
//   );
// };

// export default Otp;

// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import { Applogo, sailogo1 } from "../../../Routes/ImagePath";
// import { useSelector } from "react-redux";
// import { useNavigate, useLocation } from "react-router-dom";
// import { sendOtp, validateOtp } from "../../../services/otherServices";
// import * as yup from "yup";
// import OtpInput from "react-otp-input";
// import { useFormik } from "formik";
// import { Button, Toast, ToastContainer } from "react-bootstrap";

// // Validation schema using yup
// const schema = yup.object({
//   OTP: yup
//     .string()
//     .matches(/^\d{6}$/, "OTP must be 6 digits number")
//     .required("Kindly enter OTP")
//     .trim(),
// });

// const Otp = () => {
//   const [passData, setPassData] = useState({});
//   const [isOtpExpired, setIsOtpExpired] = useState(false);
//   const [otpSentTime, setOtpSendTime] = useState();
//   const token = useSelector((state) => state.auth);
//   const navigate = useNavigate();
//   const location = useLocation();
//   const otpData = location?.state;
//   const [isDisabled, setIsDisabled] = useState(true);
//   const { formData } = location.state || {};

//   // Toast state management
//   const [showToast, setShowToast] = useState(false);
//   const [toastMessage, setToastMessage] = useState("");
//   const [toastVariant, setToastVariant] = useState("success"); // "success" or "danger"
//   const handleToastClose = () => setShowToast(false);

//   const onSubmit = (values) => {
//     handleOtpValidation(values);
//   };

//   const handleOtpValidation = async (values) => {
//     try {
//       const body = {
//         USER_ID: otpData.userId,
//         enteredOtp: Number(values.OTP),
//         MOBILE_NO: otpData.mobileNumber,
//         EMAIL: otpData.email,
//         RESET_OPTION: otpData.resetOption,
//         SECURITY_ANSWER: "",
//         NEW_PASSWORD: otpData.newPassword,
//       };
//       const response = await validateOtp(body);
//       if (response.Is_OTP_EXPIRED === "true") {
//         // Show error toast notification
//         setToastMessage("OTP EXPIRED");
//         setToastVariant("danger");
//         setShowToast(true);
//         setIsDisabled(false);
//       } else if (response.Success === true) {
//         // Show success toast notification
//         setToastMessage("Password changed successfully");
//         setToastVariant("success");
//         setShowToast(true);

//         setTimeout(() => {
//           navigate("/sign-in");
//         }, 3000); // Redirect after 3 seconds
//       } else if (response.Success === false) {
//         // Show error toast notification
//         setToastMessage(response.message);
//         setToastVariant("danger");
//         setShowToast(true);
//       }
//     } catch (error) {
//       // Show error toast notification for any exception
//       setToastMessage(error.message || "Something went wrong");
//       setToastVariant("danger");
//       setShowToast(true);
//     }
//   };

//   const resendOtp = async () => {
//     const formData = {
//       USER_ID: localStorage.getItem("otpUserId"),
//       MOBILE_NO: localStorage.getItem("otpMobile"),
//       EMAIL: localStorage.getItem("otpEmail"),
//       METHOD: localStorage.getItem("otpMethod"),
//       SECURITY_ANSWER: "",
//     };
//     try {
//       let otpDetails = await sendOtp(formData);
//       if (otpDetails?.data) {
//         setToastMessage("OTP Resent Successfully");
//         setToastVariant("success");
//         setShowToast(true);
//       } else {
//         setToastMessage(otpDetails?.Message || "Something went wrong");
//         setToastVariant("danger");
//         setShowToast(true);
//       }
//     } catch (error) {
//       setToastMessage("There was an error processing your request.");
//       setToastVariant("danger");
//       setShowToast(true);
//     }
//   };

//   const initialValues = {
//     OTP: "",
//   };

//   const formik = useFormik({
//     initialValues,
//     onSubmit,
//     validationSchema: schema,
//   });

//   return (
//     <>
//       <ToastContainer
//         position="top-end"
//         className="p-3"
//         style={{ position: "fixed", top: 0, right: 0, zIndex: 9999 }}
//       >
//         <Toast
//           onClose={handleToastClose}
//           show={showToast}
//           delay={3000}
//           autohide
//           bg={toastVariant}
//         >
//           <Toast.Header>
//             <strong className="me-auto">
//               {toastVariant === "success" ? "Success" : "Error"}
//             </strong>
//             <small>just now</small>
//           </Toast.Header>
//           <Toast.Body>{toastMessage}</Toast.Body>
//         </Toast>
//       </ToastContainer>

//       <form onSubmit={formik.handleSubmit}>
//         <div className="account-page">
//           <div className="main-wrapper">
//             <div className="account-content">
//               <div className="container">
//                 <div className="account-logo">
//                   <Link to="/login">
//                     <img src={sailogo1} alt="error while loading image" />
//                   </Link>
//                 </div>
//                 <div className="account-box">
//                   <div className="account-wrapper">
//                     <h3 className="account-title">OTP</h3>
//                     <p className="account-subtitle">Verify your account</p>

//                     <div className="otp-wrap d-flex justify-content-center align-items-center">
//                       <OtpInput
//                         className="otp-input"
//                         value={formik.values.OTP}
//                         onChange={(otp) => {
//                           formik.setFieldValue("OTP", otp);
//                         }}
//                         numInputs={6}
//                         renderSeparator={<span>-</span>}
//                         renderInput={({ inputType = "tel", ...props }) => (
//                           <input
//                             {...props}
//                             style={{
//                               width: "2.5rem",
//                               height: "2.5rem",
//                               margin: "0 0.5rem",
//                               fontSize: "1.5rem",
//                               borderRadius: "4px",
//                               border: "1px solid #ccc",
//                               textAlign: "center",
//                             }}
//                             type={inputType}
//                           />
//                         )}
//                       />
//                     </div>
//                     {formik.errors.OTP && (
//                       <div style={{ color: "red", textAlign: "center" }}>
//                         {formik.errors.OTP}
//                       </div>
//                     )}
//                     <div className="input-block mb-2 text-center">
//                       <button
//                         className="btn btn-primary account-btn"
//                         type="submit"
//                       >
//                         Enter
//                       </button>
//                     </div>
//                     <div className="account-footer">
//                       <p>
//                         Not yet received?{" "}
//                         <Link to="/otp" onClick={resendOtp}>
//                           Resend OTP
//                         </Link>
//                       </p>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </form>
//     </>
//   );
// };

// export default Otp;
