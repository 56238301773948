import React from "react";
import Report from "../../Pages/Report";

const SubStationWise = () => {
  return (
    <div>
      <Report title="Sub Station" />
    </div>
  );
};

export default SubStationWise;
