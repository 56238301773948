import React from "react";
import Report from "../../Pages/ConsumerLinking";

const RestUNConsumerReport = () => {
  return (
    <div>
      <Report title="Feeder" title1="Rest UnLink Consumer"/>
    </div>
  );
};      

export default RestUNConsumerReport;
