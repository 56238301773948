import React from "react";
import Report from "../../Pages/Report";
const FeederWiseReport = () => {
  return (
    <div>
      <Report title="Feeder" />
    </div>
  );
};

export default FeederWiseReport;
