import React from "react";
import FeederWiseReport from "./FeederWiseReport";

const FeederWiseReportView = () => {
  return (
    <div>
      <FeederWiseReport />
    </div>
  );
};

export default FeederWiseReportView;
