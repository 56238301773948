import React from "react";
import SubDivisionWiseReport from "./SubDivisionWiseReport";

const SubDivisionWiseReportView = () => {
  return (
    <div>
      <SubDivisionWiseReport />
    </div>
  );
};

export default SubDivisionWiseReportView;
