import React from "react";
import CircleWiseReport from "./CircleWiseReport";

const CircleWiseReportView = () => {
  return (
    <div>
      <CircleWiseReport />
    </div>
  );
};

export default CircleWiseReportView;
