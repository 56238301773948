import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { emailrgx } from "../Authentication/RegEx";
import { signUp } from "../../../services/authServices";
import { Button, Toast } from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.js";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

import {
  getDesignations,
  getAllZoneUnderDesignation,
  getAllCircleUnderZone,
  getAllDivisionUnderCircle,
  getAllSubDivUnderDiv,
  getAllSubStationBySUbDiv,
  getOneDesginationDetail,
} from "../../../services/divisionServices";

import * as yup from "yup";
import Select from "react-select";
import { notification } from "antd";
import { toast } from "react-toastify";
import Toasts from "../Ui_Interface/Components/Toats";

const mobileNumberRegex = /^[0-9]{10}$/; // Regex for 10-digit mobile number
// const schema = yup.object({
//   fullName: yup
//     .string()
//     .matches(/^[A-Za-z\s]+$/, "Only alphabets are accepted")
//     .required("Full Name is required"),
//   email: yup
//     .string()
//     .matches(emailrgx, "Email is required")
//     .required("Email is required")
//     .trim(),
//   password: yup
//     .string()
//     .min(4, "Password must be at least 4 characters")
//     .max(20, "Password must be at most 20 characters")
//     .required("Password is required")
//     .trim(),
//   mobileNo: yup
//     .string()
//     .matches(mobileNumberRegex, "Mobile number must be 10 digits")
//     .required("Mobile number is required")
//     .trim(),
// });

const schema = yup.object({
  fullName: yup
    .string()
    .matches(/^[A-Za-z\s]+$/, "Only alphabets are accepted")
    .required("Full Name is required"),
  fatherName: yup
    .string()
    .matches(/^[A-Za-z\s]+$/, "Only alphabets are accepted")
    .required("Father Name is required"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required")
    .trim(),
  password: yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .max(20, "Password must be at most 20 characters")
    .required("Password is required")
    .trim(),
  repeatepassword: yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .max(20, "Password must be at most 20 characters")
    .required("Password is required")
    .trim(),
  mobileNo: yup
    .string()
    .matches(/^\d{10}$/, "Mobile number must be 10 digits")
    .required("Mobile number is required")
    .trim(),
});

const Register = (props) => {
  const [passwordEye, setPasswordEye] = useState(true); // State for password field
  const [checkUser, setCheckUser] = useState(false); // State for password field
  const [repeatPasswordEye, setRepeatPasswordEye] = useState(true); // State for repeat password field
  const [fieldEnable, setFieldEnable] = useState();
  const [designations, setDesignations] = useState([]);
  const [zones, setZones] = useState([]);
  const [circles, setCircles] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [subDivisions, setSubDivisions] = useState([]);
  const [subStations, setSubStations] = useState([]);

  const [selectedDesignation, setSelectedDesignation] = useState("");
  const [selectedZone, setSelectedZone] = useState("");
  const [selectedCircle, setSelectedCircle] = useState("");
  const [selectedDivision, setSelectedDivision] = useState("");
  const [selectedSubDivision, setSelectedSubDivision] = useState("");
  const [selectedSubStation, setSelectedSubStation] = useState("");

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const details = localStorage.getItem("loginDetails");
  const loginInfo = JSON.parse(details) || []; // Initialize as an empty array if null

  const [loginState, setLoginState] = useState(loginInfo);

  const onSubmit = async (data) => {
    // console.log("data", data);
    let signUpDetails = await signUp(data);
    // console.log("signUpDetails", signUpDetails);
    if (signUpDetails?.Success) {
      // Capture the current date as the requested date
      const reqDate = new Date().toISOString(); // Use ISO format for date consistency
      localStorage.setItem("reqDate", reqDate);
      alert("User Registered successfully");
      navigate("/energy-accounting");
    } else {
      alert(signUpDetails.message);
    }
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
        cursor: "pointer",
      },
    }),
  };

  const getDesg = async () => {
    let desgDetails = await getDesignations();
    let zoneDetails = await getAllZoneUnderDesignation();

    let newArr = [];
    let newArrZone = [];
    desgDetails?.data.map((elem) => {
      newArr.push({ value: elem.DESG, label: elem.DESG });
    });
    zoneDetails?.data.map((elem) => {
      newArrZone.push({
        value: elem.ZONE_CODE,
        label: elem.ZONE + " (" + elem.ZONE_CODE + ")",
      });
    });

    setDesignations(newArr);
    setZones(newArrZone);
  };

  // Fetch designations on api hit
  useEffect(() => {
    getDesg();
  }, [fieldEnable]);

  const handleChangeDesignation = async (designation) => {
    const desgDetail = await getOneDesginationDetail(designation);
    setFieldEnable(desgDetail[0]);
    // console.log("designation", designation);

    setSelectedDesignation(designation);
    // console.log("selectedDesignation", selectedDesignation);

    localStorage.setItem("desg", designation);
    // Clear the selected values and options for all dependent fields
    setSelectedZone(null); // Clear selected zone
    setSelectedCircle(null); // Clear selected circle
    setCircles([]); // Clear circle options
    setSelectedDivision(null); // Clear selected division
    setDivisions([]); // Clear division options
    setSelectedSubDivision(null); //=]89g5t]tClear selected sub-division
    setSubDivisions([]); // Clear sub-division options
    setSelectedSubStation(null); // Clear selected sub-station
    setSubStations([]); // Clear sub-station options
  };

  const handleChangeZone = async (zone) => {
    // console.log("zonezonezonezone", zone);
    localStorage.setItem("regZone", zone.value);
    const getCircleData = await getAllCircleUnderZone(zone.value);
    // console.log("getCircleDatagetCircleData", getCircleData);
    setSelectedZone(zone);
    let newArrCircle = [];
    getCircleData.map((elem) => {
      newArrCircle.push({ value: elem.CIRCLE_CODE, label: elem.CIRCLE });
    });
    setCircles(newArrCircle);
    setSelectedCircle(null);
    setDivisions([]); // Clear divisions
    setSelectedDivision(null); // Clear selected division
    setSubDivisions([]); // Clear sub-divisions
    setSelectedSubDivision(null); // Clear selected sub-division
    setSubStations([]); // Clear sub-stations
    setSelectedSubStation(null); // Clear selected sub-station
  };

  const handleChangeCircle = async (circle) => {
    localStorage.setItem("regCircle", circle.value);

    const getDivisionData = await getAllDivisionUnderCircle(circle.value);
    setSelectedCircle(circle);
    let newArrDivision = [];
    getDivisionData.data.map((elem) => {
      newArrDivision.push({ value: elem.DIVISION_CODE, label: elem.DIVISION });
    });
    setDivisions(newArrDivision);
    setSubDivisions([]); // Clear sub-divisions
    setSelectedSubDivision(null); // Clear selected sub-division
    setSubStations([]); // Clear sub-stations
    setSelectedSubStation(null); // Clear selected sub-station
  };

  const handleChangeDivision = async (division) => {
    localStorage.setItem("regDiv", division.value);

    const getSubDivisionData = await getAllSubDivUnderDiv(
      division.value,
      selectedCircle.value
    );

    setSelectedDivision(division);
    let newArrSubDiv = [];
    getSubDivisionData.data.map((elem) => {
      newArrSubDiv.push({
        value: elem.SUB_DIVISION_CODE,
        label: elem.SUB_DIVISION,
      });
    });
    setSubDivisions(newArrSubDiv);
    setSubStations([]); // Clear sub-stations
    setSelectedSubStation(null); // Clear selected sub-station
  };

  const handleChangeSubDivision = async (subDivision) => {
    localStorage.setItem("regSubDiv", subDivision.value);
    const getSubStationData = await getAllSubStationBySUbDiv(
      subDivision.value,
      selectedCircle.value,
      selectedDivision.value
    );
    setSelectedSubDivision(subDivision);
    let newArrSubStat = [];
    getSubStationData.data.map((elem) => {
      newArrSubStat.push({
        value: elem.SUB_STATION_CODE,
        label: elem.SUB_STATION + " (" + elem.SUB_STATION_CODE + ")",
      });
    });
    setSubStations(newArrSubStat);
  };

  const handleChangeSubStation = (subStation) => {
    localStorage.setItem("regSubStation", subStation.value);
    setSelectedSubStation(subStation);
  };

  return (
    <div className="account-page">
      <div className="main-wrapper">
        <div className="account-content w-100">
          <div className="container-lg">
            <div className="account-box w-100">
              <div className="account-wrapper">
                <h3 className="account-title mb-5">User Registeration</h3>
                <div>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row-cols-sm-auto">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Full Name&nbsp;
                              <span className="text-danger">*</span>
                            </label>
                            <Controller
                              name="fullName"
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <input
                                  className={`form-control ${
                                    errors?.fullname ? "error-input" : ""
                                  }`}
                                  type="text"
                                  value={value}
                                  onChange={onChange}
                                  autoComplete="false"
                                />
                              )}
                            />
                            <span className="text-danger">
                              {errors?.fullName?.message}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Father's Name&nbsp;
                              <span className="text-danger">*</span>
                            </label>
                            <Controller
                              name="fatherName"
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <input
                                  className={`form-control ${
                                    errors?.fullname ? "error-input" : ""
                                  }`}
                                  type="text"
                                  value={value}
                                  onChange={onChange}
                                  autoComplete="false"
                                />
                              )}
                            />
                            <span className="text-danger">
                              {errors?.fatherName?.message}
                            </span>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Mobile Number&nbsp;
                              <span className="text-danger">*</span>
                            </label>
                            <Controller
                              name="mobileNo"
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <input
                                  className={`form-control ${
                                    errors?.mobileNo ? "error-input" : ""
                                  }`}
                                  type="text"
                                  value={value}
                                  onChange={onChange}
                                  autoComplete="false"
                                />
                              )}
                            />
                            <span className="text-danger">
                              {errors?.mobileNo?.message}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Email Id&nbsp;
                              <span className="text-danger">*</span>
                            </label>
                            <Controller
                              name="email"
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <input
                                  className={`form-control ${
                                    errors?.email ? "error-input" : ""
                                  }`}
                                  type="text"
                                  value={value}
                                  onChange={onChange}
                                  autoComplete="false"
                                />
                              )}
                            />
                            <span className="text-danger">
                              {errors?.email?.message}
                            </span>
                            <span className="text-danger">
                              {checkUser ? "This Email is Already exist" : ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Password&nbsp;
                              <span className="text-danger">*</span>
                            </label>
                            <Controller
                              name="password"
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <div
                                  className="pass-group"
                                  style={{ position: "relative" }}
                                >
                                  <input
                                    type={passwordEye ? "password" : "text"}
                                    className={`form-control  ${
                                      errors?.password ? "error-input" : ""
                                    }`}
                                    value={value}
                                    onChange={onChange}
                                    autoComplete="false"
                                    pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                    title="Must contain at least one number and one uppercase and lowercase letter, and atleast 8 or more characters"
                                  />
                                  <span
                                    style={{
                                      position: "absolute",
                                      right: "5%",
                                      top: "30%",
                                    }}
                                    onClick={() => setPasswordEye(!passwordEye)}
                                    className={`fa toggle-password ${
                                      passwordEye ? "fa-eye-slash" : "fa-eye"
                                    }`}
                                  />
                                </div>
                              )}
                              defaultValue=""
                            />
                            <span className="text-danger">
                              {errors?.password?.message}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Confirm Password&nbsp;
                              <span className="text-danger">*</span>
                            </label>
                            <Controller
                              name="repeatepassword"
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <div
                                  className="pass-group"
                                  style={{ position: "relative" }}
                                >
                                  <input
                                    type={
                                      repeatPasswordEye ? "password" : "text"
                                    }
                                    className={`form-control  ${
                                      errors?.repeatPassword
                                        ? "error-input"
                                        : ""
                                    }`}
                                    value={value}
                                    onChange={onChange}
                                    autoComplete="false"
                                  />
                                  <span
                                    style={{
                                      position: "absolute",
                                      right: "5%",
                                      top: "30%",
                                    }}
                                    onClick={() =>
                                      setRepeatPasswordEye(!repeatPasswordEye)
                                    }
                                    className={`fa toggle-password ${
                                      repeatPasswordEye
                                        ? "fa-eye-slash"
                                        : "fa-eye"
                                    }`}
                                  />
                                </div>
                              )}
                              defaultValue=""
                            />
                            <span className="text-danger">
                              {errors?.repeatPassword?.message}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* Designation Dropdown */}
                        <div className="col-md-4">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Designation
                            </label>
                            <div className="col-md-12">
                              <Select
                                options={designations}
                                placeholder="-- Select --"
                                styles={customStyles}
                                name="designation"
                                onChange={(selectedOption) => {
                                  handleChangeDesignation(
                                    selectedOption?.label
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        {/* Zone Dropdown */}

                        <div className="col-md-4">
                          <div className="input-block mb-3">
                            <label className="col-form-label">Zone</label>
                            <div className="col-md-12">
                              <Select
                                options={zones && zones.length > 0 ? zones : []}
                                placeholder="-- Select Zone --"
                                value={selectedZone}
                                styles={customStyles}
                                name="zone"
                                isDisabled={
                                  fieldEnable?.ZONE == "true" ? false : true
                                }
                                onChange={(selectedOption) => {
                                  handleChangeZone(selectedOption); // Pass the selected zone code to the handler
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        {/* Circle Dropdown */}
                        <div className="col-md-4">
                          <div className="input-block mb-3">
                            <label className="col-form-label">Circle</label>
                            <div className="col-md-12">
                              <Select
                                options={
                                  circles && circles.length > 0 ? circles : []
                                }
                                placeholder="-- Select Circle --"
                                styles={customStyles}
                                value={selectedCircle}
                                name="circle"
                                isDisabled={
                                  fieldEnable?.CIRCLE == "true" ? false : true
                                }
                                onChange={(selectedOption) => {
                                  handleChangeCircle(
                                    selectedOption ? selectedOption : []
                                  ); // Pass the selected zone code to the handler
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* Division Dropdown */}
                        <div className="col-md-4">
                          <div className="input-block mb-3">
                            <label className="col-form-label">Division</label>
                            <div className="col-md-12">
                              <Select
                                options={
                                  divisions && divisions.length > 0
                                    ? divisions
                                    : []
                                }
                                placeholder="-- Select Division --"
                                value={selectedDivision}
                                styles={customStyles}
                                name="division"
                                isDisabled={
                                  fieldEnable?.DIVISION == "true" ? false : true
                                }
                                onChange={(selectedOption) => {
                                  handleChangeDivision(
                                    selectedOption !== null
                                      ? selectedOption
                                      : []
                                  ); // Pass the selected zone code to the handler
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        {/* Sub-Division Dropdown */}
                        <div className="col-md-4">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Sub-Division
                            </label>
                            <div className="col-md-12">
                              <Select
                                options={
                                  subDivisions && subDivisions.length > 0
                                    ? subDivisions
                                    : []
                                }
                                placeholder="-- Select Sub Division --"
                                value={selectedSubDivision}
                                styles={customStyles}
                                name="subDivision"
                                isDisabled={
                                  fieldEnable?.SUB_DIVISION == "true"
                                    ? false
                                    : true
                                }
                                onChange={(selectedOption) => {
                                  handleChangeSubDivision(
                                    selectedOption !== null
                                      ? selectedOption
                                      : []
                                  ); // Pass the selected zone code to the handler
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        {/* Sub-Station Dropdown */}
                        <div className="col-md-4">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Sub-Station
                            </label>
                            <div className="col-md-12">
                              <Select
                                options={
                                  subStations && subStations.length > 0
                                    ? subStations
                                    : []
                                }
                                placeholder="-- Select Sub Stations --"
                                value={selectedSubStation}
                                styles={customStyles}
                                name="subStation"
                                isDisabled={
                                  fieldEnable?.SUB_STATION == "true"
                                    ? false
                                    : true
                                }
                                onChange={(selectedOption) => {
                                  handleChangeSubStation(
                                    selectedOption !== null
                                      ? selectedOption
                                      : []
                                  ); // Pass the selected zone code to the handler
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row ">
                        <div className="input-block text-center">
                          <button
                            to="#"
                            className="btn btn-primary account-btn"
                            // onClick={handleSubmit(onSubmit)}
                          >
                            Register
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>

                  <div className="account-footer ">
                    <p>
                      Already have an account? <Link to="/">Login</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
