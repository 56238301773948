import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { SidebarData } from "./sidebardata";
import { jwtDecode } from "jwt-decode";

import * as Icon from "react-feather";
import AppContainer from "../../Routes/Appcontainer/index";

const Sidebar = () => {
  const location = useLocation();
  // const pathname = location.pathname.split("/")[1];
  const pathname = location.pathname;

  const [sidebarData, setSidebarData] = useState(SidebarData);
  const [isSidebarExpanded, setSidebarExpanded] = useState(false);
  const [isMouseOverSidebar, setMouseOverSidebar] = useState(false);
  const [submenuDrop, setSubmenudrop] = useState(false);

  const [isSideMenu, setSideMenu] = useState("");
  const [level2Menu, setLevel2Menu] = useState("");
  const [level3Menu, setLevel3Menu] = useState("");
  const [isSideMenunew, setSideMenuNew] = useState("dashboard");

  useEffect(() => {
    if (
      isMouseOverSidebar &&
      document.body.classList.contains("mini-sidebar")
    ) {
      document.body.classList.add("expand-menu");
      return;
    }
    document.body.classList.remove("expand-menu");
  }, [isMouseOverSidebar]);

  const token = localStorage.getItem("authToken");
  const USER_TYPE = jwtDecode(token)?.USER_TYPE;

  const handleMouseEnter = () => {
    setMouseOverSidebar(true);
  };

  const handleMouseLeave = () => {
    setMouseOverSidebar(false);
  };
  const { t } = useTranslation();

  const expandSubMenus = (menu) => {
    sessionStorage.setItem("menuValue", menu.menuValue);
    const updatedAdminSidebar = sidebarData.map((section) => {
      const updatedSection = { ...section };
      updatedSection.menu = section.menu.map((menuItem) =>
        menu.menuValue != menuItem.menuValue
          ? {
              ...menuItem,
              showSubRoute: false,
            }
          : {
              ...menuItem,
              showSubRoute: !menu.showSubRoute,
            }
      );
      return updatedSection;
    });
    setSidebarData(updatedAdminSidebar);
  };

  const activeRouterPath = (routesArray) => {
    return (routesArray = location.pathname);
  };

  const activeRouterMenu = (menu) => {
    return location.pathname.includes(menu.toLowerCase());
  };

  const arrowDrop = () => {
    setSubmenudrop(!submenuDrop);
  };

  const toggleSidebar = (value) => {
    setSideMenu(value);
    setSideMenuNew(value);
  };

  const toggleLvelTwo = (value) => {
    setLevel2Menu(value);
  };
  const toggleLevelThree = (value) => {
    setLevel3Menu(value);
  };

  const MenuMore = () => {
    document.getElementById("more-menu-hidden").classList.toggle("hidden");
  };

  return (
    <div
      className={`sidebar ${isSidebarExpanded ? "" : "hidden"}`}
      id="sidebar"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="sidebar-inner slimscroll" style={{ overflow: false }}>
        <div id="sidebar-menu" className="sidebar-menu">
          <nav
            className="greedys sidebar-horizantal"
            id="horizantal-sidebar"
          ></nav>
          <Scrollbars
            autoHide={false}
            autoHideTimeout={1000}
            autoHideDuration={200}
            autoHeight
            autoHeightMin={0}
            autoHeightMax="100vh"
            thumbMinSize={30}
            universal={false}
            hideTracksWhenNotNeeded={true}
          >
            <ul className="sidebar-vertical" id="veritical-sidebar">
              {sidebarData.map((mainTittle, index) => {
                return (
                  <>
                    {mainTittle.menu.map((menu, menuIndex) => {
                      return (
                        <>
                          {menu?.role?.includes(USER_TYPE) && (
                            <li className="submenu" key={menuIndex + 1}>
                              <Link
                                to="#"
                                onClick={() => expandSubMenus(menu)}
                                className={menu.showSubRoute ? "subdrop" : ""}
                              >
                                <i className={menu?.icon} />
                                <span
                                  className={
                                    menu?.menuValue == "Employees"
                                      ? "noti-dot"
                                      : ""
                                  }
                                >
                                  {t(menu.menuValue)}
                                </span>
                                <span className="menu-arrow"></span>
                              </Link>
                              <ul
                                style={{
                                  display: menu.showSubRoute ? "block" : "none",
                                }}
                              >
                                {menu.subMenus.map((subMenus, subMenu) => {
                                  return (
                                    <>
                                      {subMenus?.showMenuRoute === true ? (
                                        <li key={subMenu + 1}>
                                          <Link
                                            to={subMenus.route}
                                            className={
                                              submenuDrop ? "subdrop" : ""
                                            }
                                            onClick={arrowDrop}
                                          >
                                            {t(subMenus.menuValue)}
                                            <span className="menu-arrow"></span>
                                          </Link>

                                          <ul
                                            style={{
                                              display: submenuDrop
                                                ? "block"
                                                : "none",
                                            }}
                                          >
                                            {subMenus?.subMenusValues?.map(
                                              (value, index) => {
                                                return (
                                                  <li key={index}>
                                                    <span>
                                                      <Link to={value.route}>
                                                        <span>
                                                          {t(value.menuValue)}{" "}
                                                        </span>
                                                      </Link>
                                                    </span>
                                                  </li>
                                                );
                                              }
                                            )}
                                          </ul>
                                        </li>
                                      ) : (
                                        <li key={subMenu + 1}>
                                          <Link
                                            to={subMenus.route}
                                            className={
                                              pathname == subMenus?.route
                                                ? "active"
                                                : ""
                                            }
                                          >
                                            {t(subMenus.menuValue)}
                                          </Link>

                                          <ul>
                                            {subMenus?.subMenusValues?.map(
                                              (value, index) => {
                                                return (
                                                  <li key={index}>
                                                    <Link
                                                      to={value.route}
                                                      className={
                                                        pathname == value?.route
                                                          ? "active"
                                                          : ""
                                                      }
                                                    >
                                                      {t(value.menuValue)}
                                                    </Link>
                                                  </li>
                                                );
                                              }
                                            )}
                                          </ul>
                                        </li>
                                      )}
                                    </>
                                  );
                                })}
                              </ul>
                            </li>
                            // <li></li>
                          )}
                        </>
                      );
                    })}
                  </>
                );
              })}
            </ul>
          </Scrollbars>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
