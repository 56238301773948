import { notification } from "antd";
import Axios from "axios";
import { useDispatch } from "react-redux";

// const base_Url = "https://15.207.162.165/oms";
// const base_Url = "http://localhost:8089";
const base_Url = "https://stage-tri-energy-accounting.thesaicomputers.com/ea";


export function signUp(values) {
  // console.log("valuesvalues", values);
  let body = {
    NAME: values.fullName ? values.fullName : "",
    FATHER_NAME: values.fatherName ? values.fatherName : "",
    MOBILE_NO: values.mobileNo ? values.mobileNo : "",
    EMAIL_ID: values.email ? values.email : "",
    PASSWORD: values.password ? values.password : "",
    CONFIRM_PASSWORD: values.repeatepassword ? values.repeatepassword : "",
    DESIGNATION_CODE: localStorage.getItem("desg")
      ? localStorage.getItem("desg")
      : "",
    SECURITY_QUESTION: "ABC",
    SECURITY_ANSWER: "XYZ",
    ZONE_CODE: localStorage.getItem("regZone")
      ? localStorage.getItem("regZone")
      : "",
    CIRCLE_CODE: localStorage.getItem("regCircle")
      ? localStorage.getItem("regCircle")
      : "",
    DIVISION_CODE: localStorage.getItem("regDiv")
      ? localStorage.getItem("regDiv")
      : "",
    SUB_DIVISION_CODE: localStorage.getItem("regSubDiv")
      ? localStorage.getItem("regSubDiv")
      : "",
    SUB_STATION_CODE: localStorage.getItem("regSubStation")
      ? localStorage.getItem("regSubStation")
      : "",
  };
  // console.log("body signup", body);
  return new Promise((res, rej) => {
    Axios.post(`${base_Url}/user/register`, body)
      .then((response) => {
        res(response.data);
      })
      .catch((error) => {
        notification.error(error);
      });
  });
}

export function signIn(values) {
  const body = {
    user_id: values.email,
    password: values.password,
  };

  return Axios.post(`${base_Url}/user/login`, body)
    .then((response) => {
      const token = response.data.Body;

      // Store the token in localStorage
      localStorage.setItem("authToken", token);

      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}
