import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  uploadBillingData,
  uploadCollectionData,
  uploadFeederData,
} from "../../../services/otherServices";
import { Button, Toast, ToastContainer } from "react-bootstrap";
// import FACT_BILL from "../../../assets/FACT_BILL.csv";

const ImportData = (props) => {
  const allMonths = [
    // { label: "January", value: 1 },
    // { label: "February", value: 2 },
    // { label: "March", value: 3 },
    // { label: "April", value: 4 },
    // { label: "May", value: 5 },
    { label: "June", value: 6 },
    { label: "July", value: 7 },
    { label: "August", value: 8 },
    // { label: "September", value: 9 },
    // { label: "October", value: 10 },
    // { label: "November", value: 11 },
    // { label: "December", value: 12 },
  ];

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  const yearOptions = [{ label: "2024", value: 2024 }];

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
        cursor: "pointer",
      },
    }),
  };

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [filteredMonths, setFilteredMonths] = useState([]);
  const { register, handleSubmit, setValue, watch } = useForm();
  const navigate = useNavigate();

  const selectedYear = watch("bill_year");

  const [uploadInfo, setUploadInfo] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  // Toast state management
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState("success"); // "success" or "danger"
  const handleToastClose = () => setShowToast(false);

  useEffect(() => {
    if (currentYear === 2024) {
      const validMonths = allMonths.filter(
        (month) => month.value <= currentMonth
      );
      setFilteredMonths(validMonths);
    } else {
      setFilteredMonths(allMonths);
    }
  }, []);

  useEffect(() => {
    setValue("bill_month", localStorage.getItem("bill_month"));
    setValue("bill_year", localStorage.getItem("bill_year"));
  }, [setValue]);

  const bill_month = localStorage.getItem("bill_month") || "";
  const bill_year = localStorage.getItem("bill_year") || "";

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type === "text/csv") {
      setFile(selectedFile);
      setFileName(selectedFile.name);
    } else {
      setErrorMessage("Please upload a valid CSV file.");
      setFile(null);
      setFileName("");
    }
  };

  const handleFileRemove = () => {
    setFile(null);
    setFileName("");
  };

  const onSubmit = async (data) => {
    const { bill_month, bill_year } = data;

    setUploadInfo({});
    setErrorMessage("");

    try {
      let uploadDetails =
        (props.title === "Billing" &&
          (await uploadBillingData(file, bill_month, bill_year))) ||
        (props.title === "Collection" &&
          (await uploadCollectionData(file, bill_month, bill_year))) ||
        (props.title === "Feeder" &&
          (await uploadFeederData(file, bill_month, bill_year)));

      if (uploadDetails) {
        setUploadInfo(uploadDetails);
        setToastMessage(`${props.title} data uploaded successfully!`);
        setToastVariant("success");
        setShowToast(true); // Show success toast

        // Wait for 3 seconds before navigating
        setTimeout(() => {
          if (props.title === "Billing") {
            localStorage.setItem("billing_data_selected", true);
            navigate("/collection-data");
          } else if (props.title === "Collection") {
            navigate("/feeder-input-energy-data");
          } else if (props.title === "Feeder") {
            navigate("/zone-wise-losses-report"); // Update as necessary
          }
        }, 3000); // 3000 milliseconds = 3 seconds
      } else {
        throw new Error("An unknown error occurred.");
      }
    } catch (error) {
      setErrorMessage("An error occurred during upload.");
      setToastMessage("Error uploading data. Please try again.");
      setToastVariant("danger");
      setShowToast(true); // Show error toast
    }
  };

  const billingDataSelected = localStorage.getItem("billing_data_selected");

  // const handleDownload = async () => {
  //   try {
  //     const response = await fetch("../../../assets/FACT_BILL.csv"); // Adjust the path to your file
  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }
  //     const blob = await response.blob(); // Get the file as a Blob
  //     const url = window.URL.createObjectURL(blob); // Create a URL for the Blob

  //     const link = document.createElement("a");
  //     link.href = url; // Set the URL to the link
  //     link.setAttribute("download", "sample.csv"); // Specify the name for the downloaded file
  //     document.body.appendChild(link);
  //     link.click(); // Trigger the download
  //     document.body.removeChild(link);
  //     window.URL.revokeObjectURL(url); // Clean up the URL object
  //   } catch (error) {
  //     console.error("Error downloading file:", error);
  //   }
  // };

  return (
    <div>
      {/* Toast for success and error messages at the top-right of the whole page */}
      <ToastContainer
        position="top-end"
        className="p-3"
        style={{ position: "fixed", top: 0, right: 0, zIndex: 9999 }} // Fixed positioning and zIndex added
      >
        <Toast
          onClose={handleToastClose}
          show={showToast}
          delay={3000}
          autohide
          bg={toastVariant} // Set background based on success or error
        >
          <Toast.Header>
            <strong className="me-auto">
              {toastVariant === "success" ? "Success" : "Error"}
            </strong>
            <small>just now</small>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>

      <div className="main-wrapper">
        <div className="page-wrapper">
          <div className="content col-md-12 py-sm-5 container-fluid d-flex justify-content-center">
            <div className="row w-100 h-100 mt-5">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h1 className="card-title mb-0">
                      Import {props.title} Data
                    </h1>
                  </div>
                  <div className="card-body">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              <h5>Billing Month</h5>
                            </label>
                            <div className="col-md-12">
                              <Select
                                options={filteredMonths}
                                placeholder="-- Select Month --"
                                styles={customStyles}
                                name="bill_month"
                                onChange={(selected) => {
                                  setValue("bill_month", selected?.value);
                                  localStorage.setItem(
                                    "bill_month",
                                    selected?.value
                                  );
                                }}
                                isDisabled={
                                  billingDataSelected &&
                                  (props.title === "Collection" ||
                                    props.title === "Feeder")
                                }
                                defaultValue={
                                  allMonths.find(
                                    (m) => m.value === +bill_month
                                  ) || null
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              <h5>Billing Year</h5>
                            </label>
                            <div className="col-md-12">
                              <Select
                                options={yearOptions}
                                placeholder="-- Select --"
                                styles={customStyles}
                                name="bill_year"
                                onChange={(selected) => {
                                  setValue("bill_year", selected?.value);
                                  localStorage.setItem(
                                    "bill_year",
                                    selected?.value
                                  );
                                }}
                                isDisabled={
                                  billingDataSelected &&
                                  (props.title === "Collection" ||
                                    props.title === "Feeder")
                                }
                                defaultValue={
                                  yearOptions.find(
                                    (y) => y.value === +bill_year
                                  ) || null
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              <h5>
                                Upload a "{props.title} Data" file from your
                                Computer - .csv file only
                              </h5>
                            </label>
                            <div className="d-flex align-items-center">
                              <input
                                type="file"
                                className="form-control"
                                onChange={handleFileChange}
                                accept=".csv"
                                name="file"
                              />
                              {fileName && (
                                <Button
                                  variant="danger"
                                  onClick={handleFileRemove}
                                  className="ml-3"
                                >
                                  Remove
                                </Button>
                              )}
                            </div>
                            {errorMessage && (
                              <div className="text-danger">{errorMessage}</div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="submit-section">
                            <Button
                              className="btn btn-primary btn-block submit-btn"
                              type="submit"
                            >
                              {props.title} Data Upload
                            </Button>
                            {/* <Button variant="success" onClick={handleDownload}>
                              Download Sample CSV
                            </Button> */}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  {uploadInfo && uploadInfo.reference && (
                    <div className="card-footer">
                      <h5>Upload Summary</h5>
                      <div>
                        Reference: <strong>{uploadInfo.reference}</strong>
                      </div>
                      <div>
                        Records Processed:{" "}
                        <strong>{uploadInfo.recordsProcessed}</strong>
                      </div>
                      <div>
                        Records Ignored:{" "}
                        <strong>{uploadInfo.recordsIgnored}</strong>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImportData;
