/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Outlet, Route, Routes, Navigate } from "react-router-dom";
import Header from "../../views/layout/Header";
import Sidebar from "../../views/layout/Sidebar";
// import ChatSidebar from "../../components/Mainpages/chatSidebar";
// import ComponentSidebar from "../../components/ComponentSidebar";
// import EmailSidebar from "../../components/Mainpages/emailSidebar";
import SettingsSidebar from "../../components/SettingsSidebar";

// import Chat from "../../views/pages/MainPages/Apps/chat";
// import VoiceCall from "../../views/pages/MainPages/Apps/calls/voiceCall";
// import VideoCall from "../../views/pages/MainPages/Apps/calls/VideoCall";
// import Outgoing from "../../views/pages/MainPages/Apps/calls/outgoingCall";
// import IncomingCall from "../../views/pages/MainPages/Apps/calls/incomingCall";
// import Calendar from "../../views/pages/MainPages/Apps/calendar";
// import Email from "../../views/pages/MainPages/Apps/Email";
// import FileManager from "../../views/pages/MainPages/Apps/FileManager";
// import Compose from "../../views/pages/MainPages/Apps/Email/compose";
// import Localization from "../../views/pages/Administration/Settings/Localization";
import ThemeSettings from "../../views/pages/Administration/Settings/ThemeSettings";
// import RolesPermissions from "../../views/pages/Administration/Settings/RolesPermissions";
// import EmailSettings from "../../views/pages/Administration/Settings/EmailSettings";
// import ApprovalSetting from "../../views/pages/Administration/Settings/ApprovalSetting";
// import SalarySettings from "../../views/pages/Administration/Settings/SalarySettings";
// import NotificationSettings from "../../views/pages/Administration/Settings/NotificationSettings";
// import LeaveType from "../../views/pages/Administration/Settings/LeaveType";
import ToxboxSetting from "../../views/pages/Administration/Settings/ToxboxSetting";
import CronSetting from "../../views/pages/Administration/Settings/CronSetting";
import Search from "../../views/pages/Pages/Search/Search";
import BlankPage from "../../views/pages/Pages/BlankPage";
// import Categories from "../../views/pages/HR/Accounting/Categories";
// import SubCategory from "../../views/pages/HR/Accounting/Categories/subCategory";
// import Budgets from "../../views/pages/HR/Accounting/Budgets.jsx";
// import BudgetRevenues from "../../views/pages/HR/Accounting/BudgetRevenue";
// import OffCanvas from "../../components/OffCanvas";
import FileUpload from "../../views/pages/Ui_Interface/Forms/FileUpload.jsx";
// import EmailView from "../../views/pages/MainPages/Apps/Email/emailView.jsx";
import BillingDataView from "../../views/pages/ImportData/BillingData/index.js";
import CollectionDataView from "../../views/pages/ImportData/CollectionData/index.js";
import FeederInputDataView from "../../views/pages/ImportData/FeederInputData/index.js";
import AdminPanelView from "../../views/pages/AdminPanel/index.js";
import ZoneWiseReportView from "../../views/pages/Reports/ZoneWise/index.js";
import CircleWiseReportView from "../../views/pages/Reports/CircleWise/index.js";
import DivisionWiseReportView from "../../views/pages/Reports/DivisionWise/index.js";
import SubDivisionWiseReportView from "../../views/pages/Reports/SubDivisionWise/index.js";
import SubStationWiseReportView from "../../views/pages/Reports/SubStationWise/index.js";
import FeederWiseReportView from "../../views/pages/Reports/FeederWise/index.js";
import InputDataConfirmation from "../../views/pages/AdminPanel/components/InputDataConfirmation.js";
import GeoCordinateFromAddressView from "../../views/pages/ConsumerLinking/GeoCordinateFromAddress/index.js";
import ConsumerBeforeLinkingReport from "../../views/pages/ConsumerLinking/BeforeLinking/ConsumerBeforeLinking.js";
import RestUNConsumer from "../../views/pages/ConsumerLinking/RestUNConsumer/RestUNConsumer.js";
import ChangeConsumerFeederView from "../../views/pages/ConsumerLinking/ChangeConsumerFeeder/index.js";
import UploadData from "../../views/pages/ConsumerLinking/UploadDataForLinking/index.js";
import IframesPage from "../../views/pages/ConsumerLinking/iFrame/iframes.js";
const AppContainer = () => {
  useEffect(() => {
    localStorage.setItem("colorschema", "orange");
    localStorage.setItem("layout", "vertical");
    localStorage.setItem("layoutwidth", "fixed");
    localStorage.setItem("layoutpos", "fluid");
    localStorage.setItem("topbartheme", "light");
    localStorage.setItem("layoutSized", "lg");
    localStorage.setItem("layoutStyling", "default");
    localStorage.setItem("layoutSidebarStyle", "dark");
  }, []);

  const routingObjects = [
    {
      id: 1,
      path: "billing-data",
      element: <BillingDataView />,
      role: ["SUPERADMIN"],
    },
    {
      id: 2,
      path: "collection-data",
      element: <CollectionDataView />,
      role: ["SUPERADMIN"],
    },
    {
      id: 3,
      path: "feeder-input-energy-data",
      element: <FeederInputDataView />,
      role: ["SUPERADMIN"],
    },
    {
      id: 4,
      path: "zone-wise-losses-report",
      element: <ZoneWiseReportView />,
      role: ["SUPERADMIN", "DEPARTMENTAL"],
    },
    {
      id: 5,
      path: "circle-wise-losses-report",
      element: <CircleWiseReportView />,
      role: ["SUPERADMIN", "DEPARTMENTAL"],
    },
    {
      id: 6,
      path: "division-wise-losses-report",
      element: <DivisionWiseReportView />,
      role: ["SUPERADMIN", "DEPARTMENTAL"],
    },
    {
      id: 7,
      path: "sub-division-wise-losses-report",
      element: <SubDivisionWiseReportView />,
      role: ["SUPERADMIN", "DEPARTMENTAL"],
    },
    {
      id: 8,
      path: "sub-station-wise-losses-report",
      element: <SubStationWiseReportView />,
      role: ["SUPERADMIN", "DEPARTMENTAL"],
    },
    {
      id: 9,
      path: "feeder-wise-losses-report",
      element: <FeederWiseReportView />,
      role: ["SUPERADMIN", "DEPARTMENTAL"],
    },
    {
      id: 10,
      path: "Upload-Data",
      element: <UploadData/>,
      role: ["SUPERADMIN", "DEPARTMENTAL"],
    },
    {
      id: 11,
      path: "Consumer-Before-Linking",
      element: <ConsumerBeforeLinkingReport />,
      role: ["SUPERADMIN", "DEPARTMENTAL"],
    },
    {
      id: 12,
      path: "Consumer-After-Linking",
      element: <GeoCordinateFromAddressView />,
      role: ["SUPERADMIN", "DEPARTMENTAL"],
    },
    
    {
      id: 13,
      path: "Rest-UN-Consumer",
      element: <RestUNConsumer/>,
      role: ["SUPERADMIN", "DEPARTMENTAL"],
    },
    {
      id: 14,
      path: "Change-Consumer-Feeder",
      element: <ChangeConsumerFeederView/>,
      role: ["SUPERADMIN", "DEPARTMENTAL"],
    },
    {
      id: 15,
      path: "user-approval",
      element: <AdminPanelView />,
      role: ["SUPERADMIN"],
    },{
      id:16,
      path:"iframes",
      element: <IframesPage />,
      role: ["SUPERADMIN"],
    }
    // {
    //   id: 11,
    //   path: "input-data-confirmation",
    //   element: <InputDataConfirmation />,
    // },
  ];

  const ComponentsRoutingeObjects = [
    {
      id: 1,
      path: "components",
      // element: <Components />,
    },
  ];
  const EmailRoutingeObjects = [
    {
      id: 1,
      path: "inbox",
      // element: <Email />,
    },
    {
      id: 2,
      path: "compose",
      // element: <Compose />,
    },
    {
      id: 3,
      path: "mail-view",
      // element: <EmailView />,
    },
  ];
  const SettingsRoutingeObjects = [
    {
      id: 1,
      path: "company-settings",
      // element: <Settings />,
    },
    {
      id: 2,
      path: "localization",
      // element: <Localization />,
    },
    {
      id: 3,
      path: "theme-settings",
      element: <ThemeSettings />,
    },
    {
      id: 4,
      path: "roles-permissions",
      // element: <RolesPermissions />,
    },
    {
      id: 5,
      path: "email-settings",
      // element: <EmailSettings />,
    },
    {
      id: 6,
      path: "performance-setting",
      // element: <PerformanceSetting />,
    },
    {
      id: 7,
      path: "approval-setting",
      // element: <ApprovalSetting />,
    },
    {
      id: 8,
      path: "invoice-settings",
      // element: <InvoiceSettings />,
    },
    {
      id: 9,
      path: "salary-settings",
      // element: <SalarySettings />,
    },
    {
      id: 10,
      path: "notifications-settings",
      // element: <NotificationSettings />,
    },
    {
      id: 11,
      path: "leave-type",
      // element: <LeaveType />,
    },
    {
      id: 14,
      path: "toxbox-setting",
      element: <ToxboxSetting />,
    },
    {
      id: 15,
      path: "cron-setting",
      element: <CronSetting />,
    },
  ];
  const ProjectRoutingeObjects = [
    {
      id: 1,
      path: "tasks",
      // element: <Tasks />,
    },
  ];

  const AdministrationRoutingeObjects = [
    {
      id: 1,
      path: "assets",
      // element: <Assets />,
    },
    {
      id: 2,
      path: "user-dashboard",
      // element: <UserDashboard />,
    },
    {
      id: 3,
      path: "user-all-jobs",
      // element: <UserAllJobs />,
    },
    {
      id: 4,
      path: "saved-jobs",
      // element: <SavedJobs />,
    },
    {
      id: 5,
      path: "applied-jobs",
      // element: <AppliedJobs />,
    },

    {
      id: 6,
      path: "interviewing",
      // element: <Interviewing />,
    },
    {
      id: 7,
      path: "job-aptitude",
      // element: <JobAptitude />,
    },
    {
      id: 8,
      path: "questions",
      // element: <Questions />,
    },
    {
      id: 9,
      path: "offered-jobs",
      // element: <UserOfferedJobs />,
    },
    {
      id: 10,
      path: "visited-jobs",
      // element: <VisitedJobs />,
    },
    {
      id: 11,
      path: "archived-jobs",
      // element: <ArchivedJobs />,
    },
    {
      id: 12,
      path: "jobs-dashboard",
      // element: <JobsDashboard />,
    },
    {
      id: 13,
      path: "jobs",
      // element: <ManageJobs />,
    },
    {
      id: 14,
      path: "manage-resumes",
      // element: <ManageJobResumes />,
    },
    {
      id: 15,
      path: "shortlist-candidates",
      // element: <ShortListCandidates />,
    },
    {
      id: 16,
      path: "interview-questions",
      // element: <InterviewingQuestions />,
    },
    {
      id: 17,
      path: "offer_approvals",
      // element: <OfferApprovals />,
    },
    {
      id: 18,
      path: "experiance-level",
      // element: <ExperienceLevel />,
    },
    {
      id: 19,
      path: "candidates",
      // element: <CanditatesList />,
    },
    {
      id: 21,
      path: "schedule-timing",
      // element: <ScheduleTiming />,
    },
    {
      id: 22,
      path: "apptitude-result",
      // element: <AptitudeResults />,
    },
    {
      id: 23,
      path: "users",
      // element: <Users />,
    },
  ];

  const SidebarLayout = () => (
    <>
      <Header />
      <Sidebar />
      {/* <OffCanvas /> */}
      <Outlet />
    </>
  );
  const AuthendicationLayout = () => <div></div>;
  const ChatSidebarLayout = () => (
    <>
      <Header />
      {/* <ChatSidebar /> */}
      <Outlet />
    </>
  );
  const ComponentSidebarLayout = () => (
    <>
      <Header />
      {/* <ComponentSidebar /> */}
      <Outlet />
    </>
  );
  const EmailSidebarLayout = () => (
    <>
      <Header />
      {/* <EmailSidebar /> */}
      <Outlet />
    </>
  );
  const SettingsSidebarLayout = () => (
    <>
      <Header />
      <SettingsSidebar />
      <Outlet />
    </>
  );
  const ProjectSidebarLayout = () => (
    <>
      <Header />
      {/* <SidebarProject /> */}
      <Outlet />
    </>
  );

  const mobileResponsive = (event) => {
    const excludedHeader = document.querySelector(".header");
    const excludedSidebar = document.querySelector(".sidebar");

    if (
      !excludedHeader.contains(event.target) &&
      !excludedSidebar.contains(event.target)
    ) {
      document.body.classList.remove("slide-nav");
    }
  };

  return (
    <>
      <div className="main-wrapper" onClick={mobileResponsive}>
        <Routes>
          <Route path={"/*"} element={<SidebarLayout />}>
            {routingObjects.map((item) => (
              <Route key={item.id} path={item.path} element={item.element} />
            ))}
          </Route>

          <Route path={"/*"} element={<ComponentSidebarLayout />}>
            {ComponentsRoutingeObjects.map((item) => (
              <Route key={item.id} path={item.path} element={item.element} />
            ))}
          </Route>

          <Route path={"/*"} element={<SettingsSidebarLayout />}>
            {SettingsRoutingeObjects.map((item) => (
              <Route key={item.id} path={item.path} element={item.element} />
            ))}
          </Route>
          <Route path={"/*"} element={<ProjectSidebarLayout />}>
            {ProjectRoutingeObjects.map((item) => (
              <Route key={item.id} path={item.path} element={item.element} />
            ))}
          </Route>
          <Route path={"/*"} element={<SidebarLayout />}>
            {AdministrationRoutingeObjects.map((item) => (
              <Route key={item.id} path={item.path} element={item.element} />
            ))}
          </Route>
          <Route path={"/*"} element={<SidebarLayout />}>
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
        </Routes>
      </div>
    </>
  );
};

export default AppContainer;
