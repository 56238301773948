import React from "react";
import UploadData from "./UploadData";

const UploadDataView = () => {
  return (
    <div>
      <UploadData />
    </div>
  );
};

export default UploadDataView;
