import React, { useState, useEffect } from "react";
import Select from "react-select";
import {
  getDesignations,
  getAllZoneUnderDesignation,
  getAllCircleUnderZone,
  getAllDivisionUnderCircle,
  getAllSubDivUnderDiv,
  getAllSubStationBySUbDiv,
  getOneDesginationDetail,
  getAllFeederUnderSubStation,
} from "../../../services/divisionServices";
import { pointer } from "@testing-library/user-event/dist/cjs/pointer/index.js";
import { jwtDecode } from "jwt-decode";

const ReportDropdowns = ({
  title,
  errors,
  zone,
  circle,
  division,
  subDivision,
  subStation,
  feeder,
  setErrors,
  setFeeder,
  setZone,
  setCircle,
  setDivision,
  setSubDivision,
  setSubStation,
}) => {
  const [zones, setZones] = useState([]);
  const [circles, setCircles] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [subDivisions, setSubDivisions] = useState([]);
  const [subStations, setSubStations] = useState([]);
  const [feeders, setFeeders] = useState([]);
  const [fieldEnable, setFieldEnable] = useState();

  const [selectedZone, setSelectedZone] = useState(null);
  const [selectedCircle, setSelectedCircle] = useState(null);
  const [selectedDivision, setSelectedDivision] = useState(null);
  const [selectedSubDivision, setSelectedSubDivision] = useState(null);
  const [selectedSubStation, setSelectedSubStation] = useState(null);
  const [selectedFeeder, setSelectedFeeder] = useState("");

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
        hover: "pointer !important",
      },
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999, // Increase z-index for the menu portal
    }),
  };

  const getDesg = async () => {
    let zoneDetails = await getAllZoneUnderDesignation();
    let newArrZone = [];
    zoneDetails?.data.map((elem) => {
      newArrZone.push({
        value: elem.ZONE_CODE,
        label: elem.ZONE + " (" + elem.ZONE_CODE + ")",
      });
    });
    setZones(newArrZone);

    const token = localStorage.getItem("authToken");
    const designation = jwtDecode(token).DESG;
    const desgDetail = await getOneDesginationDetail(designation);
    setFieldEnable(desgDetail[0]);
  };

  // Fetch designations on api hit
  useEffect(() => {
    getDesg();
  }, []);

  const handleChangeZone = async (zone) => {
    setZone(zone);
    const getCircleData = await getAllCircleUnderZone(zone?.value);
    setSelectedZone(zone);
    let newArrCircle = [];
    getCircleData.map((elem) => {
      newArrCircle.push({ value: elem.CIRCLE_CODE, label: elem.CIRCLE });
    });
    setCircles(newArrCircle);
    setDivisions([]); // Clear divisions
    setSelectedDivision(null); // Clear selected division
    setSubDivisions([]); // Clear sub-divisions
    setSelectedSubDivision(null); // Clear selected sub-division
    setSubStations([]); // Clear sub-stations
    setSelectedSubStation(null); // Clear selected sub-station
  };

  const handleChangeCircle = async (circle) => {
    setCircle(circle);
    const getDivisionData = await getAllDivisionUnderCircle(circle?.value);
    setSelectedCircle(circle);
    let newArrDivision = [];
    getDivisionData.data.map((elem) => {
      newArrDivision.push({ value: elem.DIVISION_CODE, label: elem.DIVISION });
    });
    setDivisions(newArrDivision);
    setSubDivisions([]); // Clear sub-divisions
    setSelectedSubDivision(null); // Clear selected sub-division
    setSubStations([]); // Clear sub-stations
    setSelectedSubStation(null); // Clear selected sub-station
  };

  const handleChangeDivision = async (division) => {
    setDivision(division);
    const getSubDivisionData = await getAllSubDivUnderDiv(
      division?.value,
      selectedCircle?.value
    );

    setSelectedDivision(division);
    let newArrSubDiv = [];
    getSubDivisionData.data.map((elem) => {
      newArrSubDiv.push({
        value: elem.SUB_DIVISION_CODE,
        label: elem.SUB_DIVISION,
      });
    });
    setSubDivisions(newArrSubDiv);
    setSubStations([]); // Clear sub-stations
    setSelectedSubStation(null); // Clear selected sub-station
  };

  const handleChangeSubDivision = async (subDivision) => {
    setSubDivision(subDivision);
    const getSubStationData = await getAllSubStationBySUbDiv(
      subDivision?.value,
      selectedCircle?.value,
      selectedDivision?.value
    );
    setSelectedSubDivision(subDivision);
    let newArrSubStat = [];
    getSubStationData.data.map((elem) => {
      newArrSubStat.push({
        value: elem.SUB_STATION_CODE,
        label: elem.SUB_STATION + " (" + elem.SUB_STATION_CODE + ")",
      });
    });
    setSubStations(newArrSubStat);
  };

  const handleChangeSubStation = async (subStation) => {
    setSubStation(subStation);
    const getFeedersData = await getAllFeederUnderSubStation(
      subStation?.value,
      selectedSubDivision?.value,
      selectedDivision?.value,
      selectedCircle?.value
    );
    setSelectedSubStation(subStation);
    let newArrFeeder = [];
    getFeedersData.data.map((elem) => {
      newArrFeeder.push({
        value: elem.FEEDER_CODE,
        label: elem.FEEDER + " (" + elem.FEEDER_CODE + ")",
      });
    });
    setFeeders(newArrFeeder);
  };
  const handleChangeFeeder = (feeder) => {
    setSelectedFeeder(feeder);
    setFeeder(feeder);
  };

  const Zone = () => {
    return (
      <div className="col-md-6">
        <div className="input-block mb-3">
          <label className="col-form-label">
            <p className="h4 mb-3">Select Zone</p>
          </label>
          <div className="col-md-12">
            <Select
              options={zones && zones.length > 0 ? zones : []}
              placeholder="-- Select Zone --"
              styles={customStyles}
              name="zone"
              className="pointer"
              isDisabled={fieldEnable?.ZONE == "true" ? false : true}
              value={selectedZone}
              onChange={(selectedOption) => {
                handleChangeZone(selectedOption); // Pass the selected zone code to the handler
                setErrors((prevErrors) => {
                  const newErrors = { ...prevErrors };
                  delete newErrors.zone; // Remove the error for selectedYear
                  return newErrors; // Return the updated errors
                });
              
              }}
              // menuPortalTarget={document.body} // Render the dropdown in a portal to the body
            />
          </div>
          {errors.zone && <div style={{ color: 'red' }}>Zone is required</div>}
        </div>
      </div>
    );
  };
  const Circle = () => {
    return (
      <>
        <div className="col-md-6">
          <div className="input-block mb-3">
            <label className="col-form-label">
              <p className="h4 mb-3">Select Circle</p>
            </label>
            <div className="col-md-12">
              <Select
                options={circles && circles.length > 0 ? circles : []}
                placeholder="-- Select Circle --"
                styles={customStyles}
                name="circle"
                className="pointer"
                isDisabled={
                  fieldEnable?.CIRCLE == "true" && selectedZone ? false : true
                }
                value={selectedCircle}
                onChange={(selectedOption) => {
                  handleChangeCircle(selectedOption); // Pass the selected zone code to the handler
                  setErrors((prevErrors) => {
                    const newErrors = { ...prevErrors };
                    delete newErrors.circle; // Remove the error for selectedYear
                    return newErrors; // Return the updated errors
                  });
                }}
                menuPortalTarget={document.body} // Render the dropdown in a portal to the body
              />
            </div>
            {errors.circle && <div style={{ color: 'red' }}>Circle is required</div>}
          </div>
        </div>
      </>
    );
  };
  const Division = () => {
    return (
      <>
        <div className="col-md-6">
          <div className="input-block mb-3">
            <label className="col-form-label">
              <p className="h4 mb-3">Select Division</p>
            </label>
            <div className="col-md-12">
              {/* <Select
                placeholder="Select Division"
                options={divisions}
                styles={customStyles}
                isSearchable={false}
                isMulti={true}
              /> */}
              <Select
                options={divisions && divisions.length > 0 ? divisions : []}
                placeholder="-- Select Division --"
                styles={customStyles}
                name="division"
                className="pointer"
                isDisabled={
                  fieldEnable?.DIVISION == "true" && selectedCircle
                    ? false
                    : true
                }
                value={selectedDivision}
                onChange={(selectedOption) => {
                  handleChangeDivision(selectedOption); // Pass the selected zone code to the handler
                  setErrors((prevErrors) => {
                    const newErrors = { ...prevErrors };
                    delete newErrors.division; // Remove the error for selectedYear
                    return newErrors; // Return the updated errors
                  });
                }}
                menuPortalTarget={document.body} // Render the dropdown in a portal to the body
              />
            </div>
            {errors.division && <div style={{ color: 'red' }}>Division is required</div>}
          </div>
        </div>
      </>
    );
  };

  const SubDivision = () => {
    return (
      <>
        <div className="col-md-6">
          <div className="input-block mb-3">
            <label className="col-form-label">
              <p className="h4 mb-3">Select Sub Division</p>
            </label>
            <div className="col-md-12">
              <Select
                options={
                  subDivisions && subDivisions.length > 0 ? subDivisions : []
                }
                placeholder="-- Select Sub Division --"
                styles={customStyles}
                //isMulti={true} // Single select
                name="subDivision"
                className="pointer"
                isDisabled={
                  fieldEnable?.SUB_DIVISION == "true" && selectedDivision
                    ? false
                    : true
                }
                value={selectedSubDivision}
                onChange={(selectedOption) => {
                  handleChangeSubDivision(selectedOption); // Pass the selected zone code to the handler
                  setErrors((prevErrors) => {
                    const newErrors = { ...prevErrors };
                    delete newErrors.subDivision; // Remove the error for selectedYear
                    return newErrors; // Return the updated errors
                  });
                }}
                menuPortalTarget={document.body} // Render the dropdown in a portal to the body
              />
            </div>
            {errors.subDivision && <div style={{ color: 'red' }}>Sub Division is required</div>}
          </div>
        </div>
      </>
    );
  };

  const SubStation = () => {
    return (
      <>
        <div className="col-md-6">
          <div className="input-block mb-3">
            <label className="col-form-label">
              <p className="h4 mb-3">Select Sub Station</p>
            </label>
            <div className="col-md-12">
              <Select
                options={
                  subStations && subStations.length > 0 ? subStations : []
                }
                placeholder="-- Select Sub Station --"
                styles={customStyles}
                //isMulti={true} // Single select
                name="subStation"
                className="pointer"
                isDisabled={
                  fieldEnable?.SUB_STATION == "true" && selectedSubDivision
                    ? false
                    : true
                }
                value={selectedSubStation}
                onChange={(selectedOption) => {
                  handleChangeSubStation(selectedOption); // Pass the selected zone code to the handler
                  setErrors((prevErrors) => {
                    const newErrors = { ...prevErrors };
                    delete newErrors.subStation; // Remove the error for selectedYear
                    return newErrors; // Return the updated errors
                  });
                }}
                menuPortalTarget={document.body} // Render the dropdown in a portal to the body
              />
            </div>
            {errors.subStation && <div style={{ color: 'red' }}>Sub Station is required</div>}
          </div>
        </div>
      </>
    );
  };
  const Feeder = () => {
    return (
      <>
        <div className="col-md-6">
          <div className="input-block mb-3">
            <label className="col-form-label">
              <p className="h4 mb-3">Select Feeder</p>
            </label>
            <div className="col-md-12">
              <Select
                options={feeders && feeders.length > 0 ? feeders : []}
                placeholder="-- Select Feeder --"
                styles={customStyles}
                isMulti={true} // Single select
                name="feeder"
                className="pointer"
                isDisabled={!selectedSubStation}
                value={selectedFeeder}
                onChange={(selectedOption) => {
                  handleChangeFeeder(selectedOption); // Pass the selected zone code to the handler
                  setErrors((prevErrors) => {
                    const newErrors = { ...prevErrors };
                    delete newErrors.feeder; // Remove the error for selectedYear
                    return newErrors; // Return the updated errors
                  });
                }}
                menuPortalTarget={document.body} // Render the dropdown in a portal to the body
              />
            </div>
            {errors.feeder && <div style={{ color: 'red' }}>Feeder is required</div>}

          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {(title === "Zone" && (
        <div className="col-md-12">
          <div className="input-block mb-3">
            <label className="col-form-label">
              <p className="h4 mb-3">Select Zone</p>
            </label>
            <div className="col-md-12">
              <Select
                options={zones && zones.length > 0 ? zones : []}
                placeholder="-- Select Zone --"
                styles={customStyles}
                name="zone"
                className="pointer"
                isDisabled={fieldEnable?.ZONE == "true" ? false : true}
                value={selectedZone}
                onChange={(selectedOption) => {
                  handleChangeZone(selectedOption);
                }}
                menuPortalTarget={document.body} // Render the dropdown in a portal to the body
              />
            </div>
          </div>
        </div>
      )) ||
        (title === "Circle" && (
          <>
            <Zone />
            <Circle />
          </>
        )) ||
        (title === "Division" && (
          <>
            <Zone />
            <Circle />
            <Division />
          </>
        )) ||
        (title === "Sub Division" && (
          <>
            <Zone />
            <Circle />
            <Division />
            <SubDivision />
          </>
        )) ||
        (title === "Sub Station" && (
          <>
            <Zone />
            <Circle />
            <Division />
            <SubDivision />
            <SubStation />
          </>
        )) ||
        (title === "Feeder" && (
          <>
            <Zone />
            <Circle />
            <Division />
            <SubDivision />
            <SubStation />
            <Feeder />
          </>
        ))}
    </>
  );
};

export default ReportDropdowns;
