// import D:\ENERGY_ACCOUNTING_FRONTEND\enegy_accounting_frontend\node_modules\react-icons\bi\index.d.ts
import { BiMessageRoundedMinus, BiSolidFileImport } from "react-icons/bi";
export const SidebarData = [
  {
    tittle: "MAIN",
    showAsTab: false,
    separateRoute: false,
    menu: [
      {
        menuValue: "Consumer Linking",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "fa-sharp fa-solid fa-link",
        role: ["SUPERADMIN", "DEPARTMENTAL"],
        subMenus: [
          { menuValue: "Import Consumer Data", 
            route: "/Upload-Data" 
          },
          { menuValue: "Before Linking (Map View)", 
            route: "/Consumer-Before-Linking" 
          },
          { menuValue: "After Linking (Map View)", 
            route: "/Consumer-After-Linking" 
          },
          { menuValue: "Rest Unlinked Consumers", 
            route: "/Rest-UN-Consumer" 
          },
          { menuValue: "Change Consumer's Feeder", 
            route: "/Change-Consumer-Feeder" 
          },
        ],
      },
      {
        menuValue: "Import Data",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-exchange-alt",
        role: ["SUPERADMIN"],
        subMenus: [
          {
            menuValue: "Billing Data",
            route: "/billing-data",
          },
          {
            menuValue: "Collection Data",
            route: "/collection-data",
          },
          {
            menuValue: "Feeder Input Energy",
            route: "/feeder-input-energy-data",
          },
        ],
      },
      {
        menuValue: "Reports",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-pie-chart",
        role: ["SUPERADMIN", "DEPARTMENTAL"],
        subMenus: [
          {
            menuValue: "Zone Wise Losses",
            route: "/zone-wise-losses-report",
          },
          // {
          //   menuValue: "Circle Wise Losses",
          //   route: "/circle-wise-losses-report",
          // },
          // {
          //   menuValue: "Division Wise Losses",
          //   route: "/division-wise-losses-report",
          // },
          // {
          //   menuValue: "S/D Wise Losses",
          //   route: "/sub-division-wise-losses-report",
          // },
          // {
          //   menuValue: "S/S Wise Losses",
          //   route: "/sub-station-wise-losses-report",
          // },
          {
            menuValue: "FDR Wise Losses",
            route: "/feeder-wise-losses-report",
          },
        ],
      },
      // {
      //   menuValue: "Insight Visualization",
      //   hasSubRoute: false,
      //   showSubRoute: false,
      //   route: "#",
      //   icon: "la la-dice",
      //   subMenus: [],
      // },
      
      {
        menuValue: "Admin Panel",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-user-shield",
        role: ["SUPERADMIN"],
        subMenus: [
          { menuValue: "User Approval", route: "/user-approval" },
          // {
          //   menuValue: "Input Data Confirmation",
          //   route: "/input-data-confirmation",
          // },
        ],
      },
    ],
  },
];
