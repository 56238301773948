import React from "react";
import Register from "../../pages/Authentication/Register";

const SignUp = () => {
  return (
    <div>
      <Register />
    </div>
  );
  {
    /*<Select
                                  options={designations}
                                  value={designations}
                                  placeholder="-- Select --"
                                  styles={customStyles}
                                  name="designation"
                                  onClick={() =>
                                    handleChangeDesignation(designations)
                                  }
                                />*/
  }
};

export default SignUp;
