import React from "react";
import Report from "../../Pages/ConsumerLinking";

const ConsumerBeforeLinkingReport = () => {
  return (
    <div>
      <Report title="Sub Division" title1="Before Linking (Map View)"/>
    </div>
  );
};      

export default ConsumerBeforeLinkingReport;
