import React from "react";
import UserApproval from "./components/UserApproval";

const AdminPanelView = () => {
  return (
    <>
      <UserApproval />
    </>
  );
};

export default AdminPanelView;
