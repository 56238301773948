import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import AppContainer from "../Appcontainer";
import store from "../../store";
import { Provider } from "react-redux";
import Login from "../../views/pages/Authentication/Login";
import Register from "../../views/pages/Authentication/Register";
import Otp from "../../views/pages/Authentication/Otp";
import Error404 from "../../views/pages/Error/Error404";
import Error500 from "../../views/pages/Error/Error500";
import ChangePassword from "../../views/pages/Authentication/ChangePassword";
import ForgotPassword from "../../views/pages/Authentication/ForgotPassword";
import LockScreen from "../../views/pages/Authentication/LockScreen";
import Toats from "../../views/pages/Ui_Interface/Components/Toats";
import Typography from "../../views/pages/Ui_Interface/Components/Typography";
import Videos from "../../views/pages/Ui_Interface/Components/Videos";
import { Navigate } from "react-router-dom/dist";
import UiModals from "../../views/pages/Ui_Interface/Components/uimodals";
import Tooltips from "../../views/pages/Ui_Interface/Components/Tooltip";
import ComingSoon from "../../views/pages/Pages/ComingSoon";
import UnderManitenance from "../../views/pages/Pages/UnderManitenance";
// new paths
import SignIn from "../../views/signin/index";
import SignUp from "../../views/signup/index";
import AutoLogout from "../../views/pages/Authentication/AutoLogout";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const AppRouter = () => {
  useEffect(() => {
    localStorage.setItem("email", "");
    localStorage.setItem("password", "");
  }, []);
  return (
    <div>
      <Provider store={store}>
        <BrowserRouter basename="/">
          <ScrollToTop />
          <AutoLogout />
          <Routes>
            <Route path="/change-password" element={<ChangePassword />} />

            {/* NEW ROUTES START*/}
            <Route path="/" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />

            {/* NEW ROUTES END*/}

            <Route path="/otp" element={<Otp />} />
            <Route path="/error-404" element={<Error404 />} />
            <Route path="/error-500" element={<Error500 />} />
            <Route path="/coming-soon" element={<ComingSoon />} />
            <Route path="/under-maintenance" element={<UnderManitenance />} />

            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/lock-screen" element={<LockScreen />} />

            <Route path="/toastr" element={<Toats />} />
            <Route path="/tooltips" element={<Tooltips />} />
            <Route path="/typography" element={<Typography />} />
            <Route path="/video" element={<Videos />} />

            <Route path="/modals" element={<UiModals />} />
            <Route path="/*" element={<AppContainer />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </div>
  );
};

export default AppRouter;
