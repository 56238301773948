import React from "react";
import ZoneWiseReport from "./ZoneWiseReport";

const ZoneWiseReportView = () => {
  return (
    <div>
      <ZoneWiseReport />
    </div>
  );
};

export default ZoneWiseReportView;
