import React from "react";
import BillingData from "./BillingData";
const BillingDataView = () => {
  return (
    <>
      <BillingData />
    </>
  );
};

export default BillingDataView;
