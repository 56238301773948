import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button, Toast, ToastContainer, Modal } from "react-bootstrap";
import Papa from 'papaparse';

const ImportData = (props) => {
  const allMonths = [
    { label: "June", value: 6 },
    { label: "July", value: 7 },
    { label: "August", value: 8 },
  ];

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  const yearOptions = [{ label: "2024", value: 2024 }];

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
        cursor: "pointer",
      },
    }),
  };

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [filteredMonths, setFilteredMonths] = useState([]);
  const { register, handleSubmit, setValue, watch } = useForm();
  const navigate = useNavigate();
  const selectedYear = watch("bill_year");

  const [uploadInfo, setUploadInfo] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  // Toast state management
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState("success"); // "success" or "danger"
  const handleToastClose = () => setShowToast(false);

  const [csvData, setCsvData] = useState([]);
  const [firstRender, setFirstRender] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);


  const handleNavigateToIframes = () => {
    navigate("/iFrames");  // Navigate to the iframes page
  };

  useEffect(() => {
    if (currentYear === 2024) {
      const validMonths = allMonths.filter(
        (month) => month.value <= currentMonth
      );
      setFilteredMonths(validMonths);
    } else {
      setFilteredMonths(allMonths);
    }
  }, []);

  useEffect(() => {
    setValue("bill_month", localStorage.getItem("bill_month"));
    setValue("bill_year", localStorage.getItem("bill_year"));
  }, [setValue]);

  const bill_month = localStorage.getItem("bill_month") || "";
  const bill_year = localStorage.getItem("bill_year") || "";

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type === "text/csv") {
      setFile(selectedFile);
      setFileName(selectedFile.name);
      setErrorMessage("");
    } else {
      setErrorMessage("Please upload a valid CSV file.");
      setFile(null);
      setFileName("");
    }
  };

  const handleFileRemove = () => {
    setFile(null);
    setFileName("");
  };

  const handleUpload = async (e) => {
    e.preventDefault();  // Prevent default form submission
    let allData = [];

    if (file) {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const csvText = event.target.result;
        Papa.parse(csvText, {
          header: true,  // Parse CSV with headers
          skipEmptyLines: true,  // Skip empty lines
          complete: (result) => {
            allData = [...result.data];
            setCsvData(allData);  // Set the parsed data for display in the table
            setToastMessage("CSV uploaded successfully");
            setToastVariant("success");
            setShowToast(true);
          },
          error: (error) => {
            console.error("Error parsing CSV:", error);
            setToastMessage("Error parsing CSV");
            setToastVariant("danger");
            setShowToast(true);
          },
        });
      };
      reader.readAsText(file);
    } else {
      setToastMessage("Please upload a CSV file");
      setToastVariant("danger");
      setShowToast(true);
    }
  };

  return (
    <div>
      {/* Toast for success and error messages */}
      <ToastContainer
        position="top-end"
        className="p-3"
        style={{ position: "fixed", top: 0, right: 0, zIndex: 9999 }}
      >
        <Toast
          onClose={handleToastClose}
          show={showToast}
          delay={3000}
          autohide
          bg={toastVariant}
        >
          <Toast.Header>
            <strong className="me-auto">
              {toastVariant === "success" ? "Success" : "Error"}
            </strong>
            <small>just now</small>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>

      <div className="main-wrapper">
        <div className="page-wrapper">
          {/* <div className="content col-md-12 py-sm-5 container-fluid d-flex justify-content-center"> */}
          <div className="content container-fluid col-md-12 d-flex justify-content-center">
            <div className="row w-100 h-100">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h1 className="card-title mb-0">
                      Import Consumer Data
                    </h1>
                  </div>
                  <div className="card-body">
                    <form onSubmit={handleUpload}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              <h5>Billing Month</h5>
                            </label>
                            <div className="col-md-12">
                              <Select
                                options={filteredMonths}
                                placeholder="-- Select Month --"
                                styles={customStyles}
                                name="bill_month"
                                onChange={(selected) => {
                                  setValue("bill_month", selected?.value);
                                  localStorage.setItem("bill_month", selected?.value);
                                }}
                                defaultValue={
                                  allMonths.find((m) => m.value === +bill_month) || null
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              <h5>Billing Year</h5>
                            </label>
                            <div className="col-md-12">
                              <Select
                                options={yearOptions}
                                placeholder="-- Select --"
                                styles={customStyles}
                                name="bill_year"
                                onChange={(selected) => {
                                  setValue("bill_year", selected?.value);
                                  localStorage.setItem("bill_year", selected?.value);
                                }}
                                defaultValue={
                                  yearOptions.find((y) => y.value === +bill_year) || null
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              <h5>Upload a "Consumer Data" file from your Computer - .csv file only</h5>
                            </label>
                            <div className="d-flex align-items-center">
                              <input
                                type="file"
                                className="form-control"
                                onChange={handleFileChange}
                                accept=".csv"
                                name="file"
                              />
                              {fileName && (
                                <Button
                                  variant="danger"
                                  onClick={handleFileRemove}
                                  className="ml-3"
                                >
                                  Remove
                                </Button>
                              )}
                            </div>
                            {errorMessage && (
                              <div className="text-danger">{errorMessage}</div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="submit-section">
                            <Button
                              className="btn btn-primary btn-block submit-btn"
                              type="submit"
                            >
                              <i className="fa fa-upload"></i> Upload
                            </Button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                   {/* Display CSV in table format */}
      {csvData.length > 0 && (
        <div className="">
        <div className="card-header">
          <h4 className="card-title mb-0">Uploaded Data</h4>
        </div>
        <div className="card-body">
        <div style={{ maxHeight: '400px', overflowY: 'scroll' }}>
          <div className="table-responsive">
            <table className="table mb-0">
              <thead>
                <tr>
                {Object.keys(csvData[0]).map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {csvData.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {Object.values(row).map((value, colIndex) => (
                    <td key={colIndex}>{value}</td>
                  ))}
                </tr>
              ))}
           </tbody>
                    </table>
                  </div>
                  </div>
                
                  <div className="col-md-12">
                          <div className="submit-section">
                            <Button
                              className="btn btn-primary btn-block submit-btn"
                              type="submit"
                              onClick={handleNavigateToIframes}
                            >
                              <i className="fa fa-robot"></i> Run AI Modal
                            </Button>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="submit-section">
                          </div>
                        </div>
              
                </div>
                
              </div>
      )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImportData;
