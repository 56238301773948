import React from "react";
import ChangeConsumerFeeder from "./ChangeConsumerFeeder";

const ChangeConsumerFeederView = () => {
  return (
    <div>
      <ChangeConsumerFeeder />
    </div>
  );
};

export default ChangeConsumerFeederView;
