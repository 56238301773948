import { notification } from "antd";
import Axios from "axios";
import { useState } from "react";
// const base_Url = "https://15.207.162.165/oms";
const base_Url = "https://stage-tri-energy-accounting.thesaicomputers.com/ea";
 //const base_Url = "http://localhost:8089";
// const base_Url = "https://stage-oms.thesaicomputers.com/oms";
// const [val, setVal] = useState();
export function getDesignations() {
  return new Promise((res, rej) => {
    Axios.get(`${base_Url}/static/get-designation`)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        notification.error(error);
      });
  });
}
export function getAllZoneUnderDesignation() {
  return new Promise((res, rej) => {
    Axios.get(`${base_Url}/static/get-zone-by-discom?DISCOM=MEPDCL`)
      .then((response) => {
        // console.log("response", response);
        return res(response.data);
      })
      .catch((error) => {
        notification.error(error);
      });
  });
}

export function getAllCircleUnderZone(ZONE_CODE) {
  return new Promise((res, rej) => {
    Axios.get(`${base_Url}/static/get-circle-by-zone?ZONE_CODE=${ZONE_CODE}`)
      .then((response) => {
        return res(response.data.data);
      })
      .catch((error) => {
        notification.error(error);
      });
  });
}

export function getAllDivisionUnderCircle(CIRCLE_CODE) {
  return new Promise((res, rej) => {
    Axios.get(
      `${base_Url}/static/get-division-by-circle?CIRCLE_CODE=${CIRCLE_CODE}`
    )
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        notification.error(error);
      });
  });
}

export function getAllSubDivUnderDiv(DIVISION_CODE, CIRCLE_CODE) {
  return new Promise((res, rej) => {
    Axios.get(
      `${base_Url}/static/get-subdivision-by-division?DIVISION_CODE=${DIVISION_CODE}&CIRCLE_CODE=${CIRCLE_CODE}`
    )
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        notification.error(error);
      });
  });
}

export function getAllSubStationBySUbDiv(
  SUB_DIVISION_CODE,
  CIRCLE_CODE,
  DIVISION_CODE
) {
  return new Promise((res, rej) => {
    Axios.get(
      `${base_Url}/static/get-substation-by-subdivision?SUB_DIVISION_CODE=${SUB_DIVISION_CODE}&CIRCLE_CODE=${CIRCLE_CODE}&DIVISION_CODE=${DIVISION_CODE}`
    )
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {});
  });
}
export function getAllFeederUnderSubStation(
  SUB_STATION_CODE,
  SUB_DIVISION_CODE,
  DIVISION_CODE,
  CIRCLE_CODE
) {
  return new Promise((resolve, reject) => {
    Axios.get(
      `${base_Url}/static/get-feeder-by-substation?SUB_STATION_CODE=${SUB_STATION_CODE}&SUB_DIVISION_CODE=${SUB_DIVISION_CODE}&DIVISION_CODE=${DIVISION_CODE}&CIRCLE_CODE=${CIRCLE_CODE}`
    )
      .then((response) => {
        resolve(response.data); // Resolve the promise with the data
      })
      .catch((error) => {
        reject(error); // Reject with error
        notification.error(error);
      });
  });
}

export function getOneDesginationDetail(desg) {
  return new Promise((res, rej) => {
    Axios.get(`${base_Url}/static/get-desg-details/${desg}`)
      .then((response) => {
        return res(response.data.data);
      })
      .catch((error) => {
        notification.error(error);
      });
  });
}
