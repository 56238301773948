import React from "react";
import ImportData from "../../Pages/ImportData";

const FeederInputData = () => {
  return (
    <div>
      <ImportData title="Feeder" />
    </div>
  );
};

export default FeederInputData;
