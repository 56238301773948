import React from "react";
import GeoCordinateFromAddress from "./GeoCordinateFromAddress";

const GeoCordinateFromAddressView = () => {
  return (
    <div>
      <GeoCordinateFromAddress />
    </div>
  );
};

export default GeoCordinateFromAddressView;
