import React from "react";
import CollectionData from "./CollectionData";
import Header from "../../../layout/Header";
import Sidebar from "../../../layout/Sidebar";
import { Outlet } from "react-router-dom";

const CollectionDataView = () => {
  return (
    <>
      <CollectionData />
    </>
  );
};

export default CollectionDataView;
